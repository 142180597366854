import {
    accessibilityDataAtom,
    availableAuditEntriesAtom,
    currentAuditEntryAtom,
    currentAuditEntryIdAtom,
    notificationLastViewDateAtom,
    orgUsersAtom,
    todosDataAtom,
    userAtom,
    userNotificationsAtom,
    versionOfLastNotificationSeenAtom
} from '@/global-store.jsx';
import { useFetchNotifications } from '@/http/queries';
import UserAvatar from '@/pages/report/MyGoals/components/UserAvatar';
import { todoCategoryProps } from '@/pages/report/MyGoals/components/utils';
import { scrollBarStylesOnBlack } from '@assets/theme/base/scrollbarStyles.jsx';
import HeaderChip from '@components/HeaderChip.jsx';
import TabsWithPanels from '@components/TabsWithPanels';
import { BookmarkCategories } from '@helpers/constants';
import { getTimeDifferenceFromNow } from '@helpers/functions';
import {
    Avatar,
    Box,
    Button,
    Divider,
    List,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    Popover,
    Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { IconBell, IconBellRingingFilled, IconWorldWww, IconX } from '@tabler/icons-react';
import { differenceInDays } from 'date-fns';
import { useAtom } from 'jotai';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// ==============================|| PROFILE MENU ||============================== //
function NotificationSection() {
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const [versionOfLastNotificationSeen] = useAtom(versionOfLastNotificationSeenAtom);
    const [notificationLastViewDate, setNotificationLastViewDate] = useAtom(
        notificationLastViewDateAtom
    );
    const [orgUsers] = useAtom(orgUsersAtom);
    const [todosData] = useAtom(todosDataAtom);
    const { t } = useTranslation();
    const [accessibilityData] = useAtom(accessibilityDataAtom);
    const [userNotifications] = useAtom(userNotificationsAtom);

    /**
     * anchorRef is used on different components and specifying one type leads to other components throwing an error
     * */
    const anchorRef = useRef(null);

    const handleClose = event => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const handleToggle = () => {
        setNotificationLastViewDate(Date.now());
        setOpen(prevOpen => {
            return !prevOpen;
        });
    };

    const titleTextByCategory = useCallback(
        function titleTextByCategoryCb({ title, category, props, filterId }) {
            if (category === BookmarkCategories.BROKEN_LINKS) {
                return props?.url ?? title;
            } else if (
                [BookmarkCategories.PDFS_FOUND, BookmarkCategories.PDFS_UPLOAD].includes(category)
            )
                return filterId ?? props?.filterId ?? title;
            else if (
                [
                    BookmarkCategories.ISSUE,
                    BookmarkCategories.TOP_ISSUE,
                    BookmarkCategories.SITE_ISSUE
                ].includes(category)
            )
                return accessibilityData?.eaRules[props?.ruleNum]?.content || t('noName');
            else if (
                [BookmarkCategories.SITE, BookmarkCategories.SPELLCHECKER_PAGE].includes(category)
            )
                return (
                    accessibilityData.report?.summary?.summaryPerPage[props?.url]?.title ||
                    t('noName')
                );
            else if (category === BookmarkCategories.SPELLCHECKER_MISTAKE)
                return props.mistake || t('noName');

            return t('noName');
        },
        [accessibilityData, t]
    );

    const notifications = useMemo(() => {
        if (!accessibilityData) return [];

        return userNotifications.map(activity => {
            const modifierUser = activity?.modifier
                ? orgUsers?.find(user => user.id === activity?.modifier)
                : undefined;
            const todo = todosData?.todos?.[activity?.todoId];
            const boardStatus = todosData?.boardConfig
                ? Object.keys(todosData?.boardConfig).find(status => {
                      return todosData?.boardConfig[status].includes(+activity?.todoId);
                  })
                : 'backlog';
            const todoTitle = accessibilityData ? titleTextByCategory(todo) : t('noName');
            return {
                ...activity,
                modifierUser,
                notificationProps: { todo: { ...todo, title: todoTitle, status: boardStatus } }
            };
        });
    }, [orgUsers, todosData, accessibilityData, t, titleTextByCategory, userNotifications]);

    const prevOpen = useRef(open);

    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    const { data } = useFetchNotifications();

    /* useEffect(() => {
        if (data && !isLoading) {
            // eslint-disable-next-line
            setVersionOfLastNotificationSeen(__APP_VERSION__);
        }
    }, [data, isLoading, setVersionOfLastNotificationSeen]); */

    const hasUserNotifications = userNotifications.filter(
        notifi => notificationLastViewDate < notifi.activityDate
    ).length;

    const hasUpdates = versionOfLastNotificationSeen !== __APP_VERSION__; // eslint-disable-line

    const hasNewNotification = hasUserNotifications || hasUpdates;

    return (
        <>
            <HeaderChip
                Icon={hasNewNotification ? IconBellRingingFilled : IconBell}
                tooltip={t(hasNewNotification ? 'newNotifications' : 'notifications')}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup='true'
                onClick={handleToggle}
                ref={anchorRef}
                sx={
                    hasNewNotification
                        ? {
                              '& svg': {
                                  animation: 'ringBell 1.4s ease-in-out infinite alternate',
                                  transformOrigin: '50% 90%'
                              }
                          }
                        : {}
                }
            />

            <Popover
                anchorEl={anchorRef.current}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                elevation={4}
                onClose={handleClose}
                open={open}
                slotProps={{
                    paper: {
                        sx: {
                            p: 0,
                            borderRadius: theme.borders.borderRadius.xl,
                            background: theme.palette.primary.main,
                            ...scrollBarStylesOnBlack
                        }
                    }
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
            >
                <Box
                    sx={{
                        maxHeight: { xs: 'calc(100vh - 28px)', sm: 'calc(100vh - 65px)' },
                        p: 3.5,
                        pb: 2.5,
                        width: { xs: 'calc(100vw - 28px)', sm: '430px' },
                        color: '#fff',
                        overflow: 'auto',
                        ...scrollBarStylesOnBlack
                    }}
                >
                    <Box>
                        <Tabs
                            notifications={notifications}
                            orgUsers={orgUsers}
                            data={data}
                            hasNewNotification
                            hasUserNotifications={hasUserNotifications}
                            hasUpdates={hasUpdates}
                            versionOfLastNotificationSeen={versionOfLastNotificationSeen}
                        />

                        <Button
                            color='white'
                            fullWidth
                            onClick={handleClose}
                            startIcon={<IconX />}
                            sx={{ my: 2, outlineOffset: '-3px' }}
                            variant='outlined'
                        >
                            {t('close')}
                        </Button>
                    </Box>
                </Box>
            </Popover>
        </>
    );
}

function Tabs({ notifications, orgUsers, data, hasUserNotifications, hasUpdates }) {
    const { t } = useTranslation();
    const [, setVersionOfLastNotificationSeen] = useAtom(versionOfLastNotificationSeenAtom);

    const tabs = [
        {
            title: t('notifications'),
            content: <NotificationsTab notifications={notifications} orgUsers={orgUsers} />,
            showDot: hasUserNotifications
        },
        {
            title: t('updates'),
            content: <UpdatesTab data={data} />,
            // eslint-disable-next-line
            showDot: hasUpdates,
            onClick: () => {
                // eslint-disable-next-line
                setVersionOfLastNotificationSeen(__APP_VERSION__);
            }
        }
    ];

    return <TabsWithPanels tabs={tabs} tabTextColor='#fff' />;
}

function UpdatesTab({ data }) {
    const { t } = useTranslation();
    return (
        <List sx={{ mt: 1.25 }}>
            {data?.map((notification, index) => (
                <ListItem
                    key={notification.date + notification.version}
                    sx={[
                        {
                            border: '1.5px solid #ccc',
                            borderRadius: 1.5,
                            px: 2,
                            py: 1.5
                        },
                        index === data.length - 1
                            ? {
                                  mb: 0
                              }
                            : {
                                  mb: 2
                              }
                    ]}
                >
                    <ListItemText>
                        <Box
                            sx={{
                                fontWeight: 600,
                                display: 'flex',
                                flexDirection: 'column',
                                mb: 1
                            }}
                        >
                            <Box>
                                {t('date')}: {notification.date}
                            </Box>

                            <Box>Version: {notification.version}</Box>
                        </Box>

                        <Box
                            component='ul'
                            sx={{
                                listStyleType: 'disc',
                                pl: 3
                            }}
                        >
                            {notification.updates?.map(update => (
                                <Box component='li' key={update}>
                                    {update}
                                </Box>
                            ))}
                        </Box>
                    </ListItemText>
                </ListItem>
            ))}
        </List>
    );
}

function NotificationsTab({ notifications }) {
    const { t, i18n } = useTranslation();
    const [user] = useAtom(userAtom);
    const lang = i18n.resolvedLanguage;
    const navigate = useNavigate();
    const [availableAuditEntries] = useAtom(availableAuditEntriesAtom);
    const [, setCurrentAuditEntryId] = useAtom(currentAuditEntryIdAtom);
    const [currentAuditEntry] = useAtom(currentAuditEntryAtom);

    const handleClicked = activity => {
        const todoDomain = activity.notificationProps.todo.domain;
        if (currentAuditEntry?.domain !== todoDomain) {
            setCurrentAuditEntryId(
                availableAuditEntries.find(entry => entry.domain === todoDomain)?.id
            );
        }
        navigate('/report/my-goals', { state: { todoId: activity?.todoId, openDetail: true } });
    };

    return notifications.length ? (
        <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
            {notifications
                ?.sort((a, b) => b.activityDate - a.activityDate)
                .map((activity, index) => {
                    const { modifierUser, notificationProps } = activity;

                    const { icon: BookmarkIcon } =
                        todoCategoryProps[notificationProps?.todo?.category];

                    const timeDifference = getTimeDifferenceFromNow(
                        new Date(activity.activityDate),
                        lang
                    );
                    const datesDifference = differenceInDays(
                        new Date(),
                        new Date(activity.activityDate)
                    );
                    const locale = lang === 'de' ? 'de-DE' : 'en-US';
                    const activityDateStr =
                        datesDifference < 2
                            ? timeDifference
                            : new Date(activity.activityDate).toLocaleString(locale, {
                                  dateStyle: 'full',
                                  timeStyle: 'medium'
                              });

                    const notificationTitlesMap = {
                        4: t('assignedYouTodo'),
                        6: t('addedACommentNotifi'),
                        8: t('changedDescriptionNotifi')
                    };

                    return (
                        <React.Fragment key={`${activity.type}-${index}`}>
                            <ListItemButton
                                alignItems='flex-start'
                                sx={[
                                    {
                                        border: '1.5px solid #ccc',
                                        borderRadius: 1.5,
                                        px: 2,
                                        py: 1.5
                                    },
                                    index === notifications.length - 1
                                        ? {
                                              mb: 0
                                          }
                                        : {
                                              mb: 2
                                          }
                                ]}
                                onClick={() => handleClicked(activity)}
                            >
                                <ListItemAvatar>
                                    {modifierUser ? (
                                        <UserAvatar
                                            hideName={true}
                                            user={modifierUser}
                                            me={+modifierUser.id === +user.id}
                                        />
                                    ) : (
                                        <Avatar
                                            alt='Eye-Able'
                                            src='https://assets.eye-able.com/dashboard-assets/icons/Icon_Dashboard_eye-able_black.svg'
                                        />
                                    )}
                                </ListItemAvatar>
                                <ListItemText
                                    primary={
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                gap: 1
                                            }}
                                        >
                                            {`${modifierUser.surname} ${modifierUser.name} ${notificationTitlesMap[activity.type]} `}
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    gap: 1,
                                                    alignItems: 'center'
                                                }}
                                            >
                                                <BookmarkIcon />
                                                <Typography
                                                    variant='body1'
                                                    sx={{
                                                        flex: 1,
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis'
                                                    }}
                                                >
                                                    {notificationProps?.todo?.title}
                                                </Typography>
                                            </Box>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    gap: 1,
                                                    alignItems: 'center'
                                                }}
                                            >
                                                <IconWorldWww />
                                                <Typography
                                                    variant='body1'
                                                    sx={{
                                                        flex: 1,
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis'
                                                    }}
                                                >
                                                    {notificationProps?.todo?.domain}
                                                </Typography>
                                            </Box>
                                            <Typography
                                                sx={{ display: 'inline' }}
                                                component='span'
                                                variant='body2'
                                            >
                                                {activityDateStr}
                                            </Typography>
                                        </Box>
                                    }
                                />
                            </ListItemButton>
                            {index !== notifications.length - 1 ? (
                                <Divider variant='inset' component='li' />
                            ) : null}
                        </React.Fragment>
                    );
                })}
        </List>
    ) : (
        <Box sx={{ p: 1 }}>{t('noNewNotifications')}</Box>
    );
}

export default NotificationSection;

// from https://jasonwatmore.com/post/2020/04/18/fetch-a-lightweight-fetch-wrapper-to-simplify-http-requests
/*
Example usage:
fetchWrapper.post('https://jsonplaceholder.typicode.com/posts', { title: 'Fetch Wrapper POST Request Example' })
    .then(data => console.log('Success!', data))
    .catch(error => console.error('There was an error!', error));
 */

import { apiUrl } from '@http/constants';

function addParamsToUrl(url, params) {
    let queryString = new URLSearchParams(params).toString();
    return url.includes('?') ? `${url}&${queryString}` : `${url}?${queryString}`;
}

export function getRequestHeaders(url, contentType = 'application/json') {
    const headers = {};
    if (contentType) headers['Content-Type'] = contentType;

    //console.log('getHeaders', url, 'session demoMode', sessionStorage.getItem('demoMode'));

    if (localStorage.getItem('demoMode') === 'true' && url.includes(apiUrl))
        headers['X-Demo-Mode'] = 'true';
    else if (!import.meta.env.PROD && url.includes(apiUrl))
        headers['X-Dev-Token'] = localStorage.getItem('X-Dev-Token');

    return headers;
}

const fetchWrapper = {
    get,
    post,
    put,
    delete: _delete,
    streamRequest
};

async function streamRequest({
    url,
    body,
    params,
    onChunkReceived,
    onStreamCompleted,
    contentType = 'application/json'
}) {
    const requestOptions = {
        method: 'POST',
        credentials: import.meta.env.PROD && url.includes(apiUrl) ? 'include' : 'omit',
        headers: getRequestHeaders(url, contentType)
    };
    if (body) {
        if (contentType === 'application/json') requestOptions.body = JSON.stringify(body);
        else requestOptions.body = body;
    }
    if (params) url = addParamsToUrl(url, params);

    const response = await fetch(url, requestOptions);

    if (!response.ok) {
        const status = response.status;
        const body = await response.text();
        if (response.headers.get('Content-Type')?.includes('json')) {
            const error = JSON.parse(body);
            const message = error.message || error.error || error;
            console.info('fetchWrapper streamRequest error:', status, message);
            return Promise.reject({ ok: false, status, message });
        }

        const message = response.statusText + (body ? `: ${body}` : '');
        console.info('fetchWrapper streamRequest error:', status, message);
        return Promise.reject({ ok: false, status, message });
    }

    const reader = response.body.getReader();
    const decoder = new TextDecoder('utf-8');

    let dataReceived = '';

    // Reading the stream
    // eslint-disable-next-line no-constant-condition
    while (true) {
        const { done, value } = await reader.read();
        if (done) {
            console.log('Stream completed', dataReceived);
            onStreamCompleted?.(dataReceived);
            break;
        }
        const chunk = decoder.decode(value /* , { stream: true } */);
        dataReceived += chunk;
        console.log('Received Chunk:', chunk);
        onChunkReceived?.(chunk);
    }

    return dataReceived;
}

function get(url, params) {
    if (!url) return console.error('fetchwrapper.get: url is required');
    const requestOptions = {
        method: 'GET',
        credentials: import.meta.env.PROD && url.includes(apiUrl) ? 'include' : 'omit',
        headers: getRequestHeaders(url, null)
    };
    if (params) url = addParamsToUrl(url, params);
    return fetch(url, requestOptions).then(handleResponse);
}

function post(url, body, params, contentType = 'application/json') {
    if (!url) return console.error('fetchwrapper.post: url is required');
    const requestOptions = {
        method: 'POST',
        credentials: import.meta.env.PROD && url.includes(apiUrl) ? 'include' : 'omit',
        headers: getRequestHeaders(url, contentType)
    };
    if (body) {
        if (contentType === 'application/json') requestOptions.body = JSON.stringify(body);
        else requestOptions.body = body;
    }
    if (params) url = addParamsToUrl(url, params);
    return fetch(url, requestOptions).then(handleResponse);
}

function put(url, body, params, contentType = 'application/json') {
    if (!url) return console.error('fetchwrapper.put: url is required');
    const requestOptions = {
        method: 'PUT',
        credentials: import.meta.env.PROD && url.includes(apiUrl) ? 'include' : 'omit',
        headers: getRequestHeaders(url, contentType)
    };

    if (body) {
        if (contentType === 'application/json') requestOptions.body = JSON.stringify(body);
        else requestOptions.body = body;
    }

    if (params) url = addParamsToUrl(url, params);
    return fetch(url, requestOptions).then(handleResponse);
}

// prefixed with underscored because delete is a reserved word in javascript
function _delete(url, params) {
    if (!url) return console.error('fetchwrapper.delete: url is required');
    const requestOptions = {
        method: 'DELETE',
        credentials: import.meta.env.PROD && url.includes(apiUrl) ? 'include' : 'omit',
        headers: getRequestHeaders(url, null)
    };
    if (params) url = addParamsToUrl(url, params);
    return fetch(url, requestOptions).then(handleResponse);
}

// reject promise if response is not ok and return server error message
async function handleResponse(response) {
    if (response.headers.get('Content-Type')?.includes('pdf')) return response.blob();

    const text = await response.text();

    if (!response.ok) {
        let message = response.statusText;
        if (text && response.headers.get('Content-Type')?.includes('json')) {
            try {
                const data = JSON.parse(text);
                message = data.message || data.error || data || message;
                response.json = () => {
                    return Promise.resolve({ ...data, message });
                };
            } catch (e) {
                message = 'Invalid JSON response';
                console.info('Error parsing JSON:', e);
                response.json = () => {
                    return Promise.resolve(message);
                };
            }
        }

        response.message = message;
        response.text = () => {
            return Promise.resolve(message);
        };

        console.info('fetchWrapper response error:', response);

        return Promise.reject(response);
    }

    let data = text;
    if (text && response.headers.get('Content-Type')?.includes('json')) {
        try {
            data = JSON.parse(text);
        } catch (e) {
            console.info('Error parsing JSON:', e);
            return Promise.reject({
                ok: false,
                status: 500,
                statusText: 'Invalid JSON response',
                message: 'Invalid JSON response'
            });
        }
    }

    return data;
}

export default fetchWrapper;

import { demoModeAtom } from '@/global-store.jsx';
import LanguageSelect from '@components/LanguageSelect.jsx';
import SupportPopupButton from '@components/SupportPopupButton.jsx';
import { sidebarWidth } from '@helpers/constants.js';
import NotificationSection from '@layout/Header/NotificationSection.jsx';
import { Box, Drawer, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import LogoSection from '../Header/LogoSection.jsx';
import ProfileSection from '../Header/ProfileSection.jsx';
import MenuList from './MenuList/index.jsx';

// ==============================|| SIDEBAR DRAWER ||============================== //
const Sidebar = ({ drawerOpen, drawerToggle, window }) => {
    const theme = useTheme();
    const xlAndUp = useMediaQuery(theme.breakpoints.up('xl'));
    const { t } = useTranslation();

    const [demoMode] = useAtom(demoModeAtom);

    const container = window !== undefined ? () => window.document.body : undefined;

    return (
        <Box
            className='no-print'
            component='nav'
            aria-label={t('mainNavigation')}
            sx={[
                {
                    flexShrink: { lg: 0 },
                    maxWidth: '100vw',
                    background: 'transparent',

                    // First value is the fallback, second is the preferred value
                    height: ['100vh', '100dvh'],

                    overflow: 'hidden'
                },
                xlAndUp
                    ? {
                          paddingTop: '64px'
                      }
                    : {
                          paddingTop: 0
                      },
                xlAndUp
                    ? {
                          width: sidebarWidth
                      }
                    : {
                          width: 'auto'
                      }
            ]}
            role='navigation'
        >
            {xlAndUp ? (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexWrap: 'nowrap',
                        width: sidebarWidth,
                        position: 'fixed',
                        bottom: 0,
                        left: 0,
                        height: 'calc(100% - 64px)',
                        overflow: 'hidden',
                        color: '#fff'
                    }}
                >
                    <MenuList />

                    {demoMode ? null : (
                        <Box
                            id='support-popup'
                            sx={{
                                p: 1.1,
                                pb: 1.4,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                gap: 0.75,
                                backgroundColor: 'rgba(0, 0, 0, 0.25)',
                                fontWeight: 500
                            }}
                        >
                            {t('needHelp')}

                            <SupportPopupButton />
                        </Box>
                    )}
                </Box>
            ) : (
                <Drawer
                    className='no-print'
                    container={container}
                    variant='temporary'
                    anchor='left'
                    open={drawerOpen}
                    onClose={drawerToggle}
                    sx={{
                        'color': '#fff',
                        'overflow': 'hidden',
                        'margin': 0,
                        'borderRadius': 0,
                        'background': 'transparent',
                        'display': 'flex',
                        'flexDirection': 'column',
                        '& .MuiDrawer-paper': {
                            color: '#fff',
                            margin: 0,
                            borderRadius: 0,
                            width: sidebarWidth,
                            maxWidth: '100vw',
                            background: 'transparent',
                            overflow: 'hidden',
                            borderRight: 'none',
                            backgroundImage:
                                'linear-gradient(160deg, rgb(0, 0, 0) 20%, rgb(85, 85, 85) 100%) !important'
                        }
                    }}
                    ModalProps={{ keepMounted: true }}
                    color='inherit'
                >
                    <Box sx={{ pl: 2, pt: 1 }}>
                        <LogoSection />
                    </Box>

                    <Box
                        sx={{
                            gap: 2,
                            pt: 0.5,
                            pb: 1.5,
                            mx: 3.75,
                            justifyContent: 'center',
                            alignItems: 'center',
                            display: { xs: 'flex', sm: 'none' },
                            borderBottom: '1.25px solid #aaa'
                        }}
                    >
                        <ProfileSection />
                        <NotificationSection />
                        <LanguageSelect />
                    </Box>

                    <MenuList />

                    {demoMode ? null : (
                        <Box
                            id='support-popup'
                            sx={{
                                p: 1.1,
                                pb: 1.4,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                gap: 0.75,
                                backgroundColor: 'rgba(0, 0, 0, 0.25)',
                                fontWeight: 500
                            }}
                        >
                            {t('needHelp')}

                            <SupportPopupButton />
                        </Box>
                    )}
                </Drawer>
            )}
        </Box>
    );
};

Sidebar.propTypes = {
    drawerOpen: PropTypes.bool,
    drawerToggle: PropTypes.func,
    window: PropTypes.object
};

export default Sidebar;

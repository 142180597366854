import ProgressbarColors from '@helpers/progressbar-colors';
import { Box, CircularProgress, Typography, useMediaQuery } from '@mui/material';
import PropTypes from 'prop-types';

/**
 * CircularRatingWithInnerLabel displays a circular progress bar with a label inside.
 * @param rating{number|string} - The rating to display.
 * @param label{string} - The label to display.
 * @param small{boolean} - Whether to display the component in a smaller size.
 * @param dark{boolean} - Whether to use dark colors.
 * @param sx{Object} - Additional styles to apply.
 * @returns {JSX.Element}
 */
function CircularRatingWithInnerLabel({
    rating,
    prevRating = null,
    small = false,
    dark = false,
    fillColor = null,
    label,
    sx
}) {
    const xlUp = useMediaQuery(theme => theme.breakpoints.up('xl'));
    const value = Number.parseFloat(rating);

    let size = 16;
    if (small) size = 13;
    else if (xlUp) size += 4;

    return (
        <Box
            sx={[
                {
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    position: 'relative'
                },
                ...(Array.isArray(sx) ? sx : [sx])
            ]}
        >
            <CircularProgress
                variant='determinate'
                sx={theme => ({
                    color: theme.palette.grey[300]
                })}
                size={size + 'rem'}
                thickness={4}
                value={100}
            />
            <CircularProgress
                aria-label={label}
                thickness={4}
                size={size + 'rem'}
                sx={{
                    'position': 'absolute',
                    '& .MuiCircularProgress-svg': {
                        strokeLinecap: 'round',
                        backgroundColor: 'transparent',
                        color: fillColor ?? `${ProgressbarColors(value, dark)}`
                    }
                }}
                value={value}
                variant='determinate'
            />
            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                {label ? (
                    <Typography
                        sx={{
                            fontSize: small ? '0.85rem' : { xs: '0.85rem', xl: '1rem' },
                            fontWeight: 'bold'
                        }}
                    >
                        {label}
                    </Typography>
                ) : null}

                <Typography
                    sx={{
                        fontSize: {
                            xs: small ? '1.6rem' : '2.6rem',
                            xl: small ? '2.6rem' : '3.3rem'
                        },
                        fontWeight: 'bold'
                    }}
                >
                    {value}%
                </Typography>

                {prevRating && (
                    <Typography
                        sx={{
                            fontSize: small ? '1.2rem' : { xs: '1.2rem', xl: '1.4rem' },
                            color: 'text.secondary',
                            textDecoration: 'line-through',
                            fontWeight: 500
                        }}
                    >
                        {prevRating}%
                    </Typography>
                )}
            </Box>
        </Box>
    );
}

CircularRatingWithInnerLabel.propTypes = {
    label: PropTypes.string,
    rating: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    small: PropTypes.bool,
    dark: PropTypes.bool,
    sx: PropTypes.object
};

export default CircularRatingWithInnerLabel;

export function PlainLanguageIcon(props) {
    return (
        <svg
            className='custom-svg-icon'
            id='plain-lang-icon'
            data-name='Ebene 1'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 24 24'
            {...props}
        >
            <defs>
                <style>
                    {`
                        #plain-lang-icon .cls-1 {
                            stroke-miterlimit: 10;
                        }
                        #plain-lang-icon .cls-1, #plain-lang-icon .cls-2 {
                            fill: none;
                            stroke: currentColor;
                            stroke-linecap: round;
                            stroke-width: 1.35px;
                        }
                        #plain-lang-icon .cls-2 {
                            stroke-linejoin: round;
                        }
                    `}
                </style>
            </defs>
            <ellipse className='cls-2' cx='11.99' cy='4.84' rx='2.92' ry='2.84' />
            <path
                className='cls-2'
                d='M11.95,22l-7.99-2.93c-.4-.15-.67-.53-.67-.96l.05-9.26c0-.71.71-1.19,1.37-.95l7.3,2.67-.06,11.42Z'
            />
            <path
                className='cls-2'
                d='M12.05,22l7.99-2.93c.4-.15.67-.53.67-.96l-.05-9.26c0-.71-.71-1.19-1.37-.95l-7.3,2.67.06,11.42Z'
            />
            <line className='cls-1' x1='9.6' y1='13.98' x2='5.69' y2='12.54' />
            <line className='cls-1' x1='9.6' y1='17.3' x2='5.69' y2='15.85' />
        </svg>
    );
}

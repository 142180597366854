import { demoModeAtom, selectedDomainAtom, userAtom } from '@/global-store';
import useFormSchema from '@/hooks/useFormSchema';
import DynamicDialog from '@components/DynamicDialog';
import PhoneInput from '@components/PhoneInput';
import useWindowSize from '@hooks/useWindowSize';
import { usePostOfferRequest } from '@http/queries';
import { Box, Button, Checkbox, Chip, ListItemText, MenuItem } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { IconCalendarEvent, IconSend } from '@tabler/icons-react';
import { useAtom } from 'jotai';
import { Select, TextField } from 'mui-rff';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import RouterLink from './RouterLink.jsx';

function OfferContactForm({
    product = null,
    size = 'medium',
    fullWidthMobile = false,
    buttonColor = 'primary',
    buttonText = null,
    showBookConsultation = false
}) {
    const { validate, required } = useFormSchema(
        yup.object({
            name: yup.string().trim().max(254).required(),
            email: yup.string().trim().email().max(128).required(),
            domain: yup.string().trim().max(254).required(),
            message: yup.string().trim().max(5000).required(),
            selectedProducts: yup.array().of(yup.string().trim().max(254)).nullable(),
            phone: yup.string().trim().max(20).nullable()
        })
    );

    const { t } = useTranslation();

    const [user] = useAtom(userAtom);
    const [demoMode] = useAtom(demoModeAtom);

    const [selectedDomain] = useAtom(selectedDomainAtom);

    const windowsSize = useWindowSize().width;
    const isMobileOrTablet = windowsSize < 1190;

    const fullWidth = isMobileOrTablet && fullWidthMobile;

    const sendRequest = usePostOfferRequest();

    const products = [
        'Eye-Able® Report',
        'Eye-Able® Audit',
        'Eye-Able® Assist',
        'Eye-Able® Ally',
        'Lighthouse',
        t('plainLanguage'),
        t('accessibilityStatementGenerator')
    ];

    //send support request to api
    async function handleSendRequest(values, form) {
        const supportMsg = `Category: Offer Request Form\n
                            Domain: ${values.domain}\n
                            Current Page: ${document.title} | ${window.location.href}\n
                            Name: ${values.name} \n
                            Mail: ${values.email}\n
                            Phone: ${values.phone}\n
                            Message: ${values.message}\n
                            Product: ${values.selectedProducts?.join(' & ')}`;

        try {
            const response = await sendRequest.mutateAsync(supportMsg);

            console.log('Offer Request Form response:', response);

            form.restart();

            return true; // close the modal
        } catch (error) {
            console.error(error);
            return false;
        }
    }

    const closeAction = form => {
        form.restart();
        return true;
    };

    const title = demoMode
        ? 'Demo - ' + t('contactFormTitle')
        : t('offer') + ' - ' + t('contactFormTitle');

    return (
        <Box
            sx={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: fullWidth ? 0 : isMobileOrTablet ? 1.5 : 2.5
            }}
        >
            {showBookConsultation ? (
                <Button
                    component={RouterLink}
                    color={buttonColor}
                    fullWidth={fullWidth}
                    size={size}
                    startIcon={<IconCalendarEvent />}
                    sx={
                        fullWidth
                            ? {
                                  borderTop: '1.5px solid #000',
                                  boxShadow: 0
                              }
                            : {}
                    }
                    href='https://calendly.com/eye-able-accessibility-solutions/kostenloses-beratungsgesprach'
                    variant='contained'
                >
                    {t('bookConsultation')}
                </Button>
            ) : null}
            <Form
                initialValues={{
                    message: t('featureContactFormMessage') + ' ' + (product || ''),
                    domain: demoMode ? '' : selectedDomain,
                    name: demoMode ? '' : user.fullName,
                    email: demoMode ? '' : user.email,
                    phone: demoMode ? '' : user.phone,
                    selectedProducts: product ? [product] : ''
                }}
                onSubmit={handleSendRequest}
                render={({ handleSubmit, form, values }) => (
                    <DynamicDialog
                        id='offer-contact-button'
                        activatorProps={{
                            variant: showBookConsultation ? 'outlined' : 'contained',
                            color: buttonColor,
                            startIcon: <IconSend />,
                            size: size,
                            fullWidth: fullWidth,
                            sx: fullWidth
                                ? {
                                      borderTop: '1.5px solid #000',
                                      boxShadow: 0
                                  }
                                : {}
                        }}
                        activatorText={buttonText ? buttonText : t('OfferContactFormButton')}
                        onCancel={() => closeAction(form)}
                        onConfirm={handleSubmit}
                        confirmBtnIcon={<IconSend />}
                        confirmBtnText={t('OfferContactFormSubmit')}
                        title={title}
                    >
                        <form onSubmit={handleSubmit}>
                            <Grid
                                container
                                direction='column'
                                sx={{
                                    gap: 2.5,
                                    my: 1.5
                                }}
                            >
                                {demoMode ? (
                                    <>
                                        <TextField
                                            label={t('name')}
                                            name='name'
                                            required={required.name}
                                        />

                                        <TextField
                                            name='email'
                                            label={t('email')}
                                            variant='outlined'
                                            required={required.email}
                                        ></TextField>

                                        <PhoneInput name='phone' required />

                                        <TextField
                                            name='domain'
                                            label={t('domain')}
                                            variant='outlined'
                                            required={required.domain}
                                        ></TextField>
                                    </>
                                ) : null}

                                <Select
                                    label={t('wantedProducts')}
                                    multiple
                                    name='selectedProducts'
                                    renderValue={selected => (
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                            {selected.map(value => (
                                                <Chip key={value} label={value} />
                                            ))}
                                        </Box>
                                    )}
                                    variant='outlined'
                                >
                                    {products.map(product => (
                                        <MenuItem key={product} value={product}>
                                            <Checkbox
                                                checked={
                                                    values.selectedProducts.indexOf(product) > -1
                                                }
                                            />

                                            <ListItemText primary={product} />
                                        </MenuItem>
                                    ))}
                                </Select>

                                <TextField
                                    fullWidth
                                    label={t('message')}
                                    multiline
                                    name='message'
                                    required={required.message}
                                    rows={4}
                                    variant='outlined'
                                />
                            </Grid>
                        </form>
                    </DynamicDialog>
                )}
                validate={validate}
            />
        </Box>
    );
}

export default OfferContactForm;

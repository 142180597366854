import DynamicDialog from '@/components/DynamicDialog';
import UserDialog from '@/components/UserDialog';
import { userAtom } from '@/global-store';
import useLogoutFn from '@/hooks/useLogoutFn.jsx';
import ChangePasswordForm from '@/pages/authentication/auth-forms/ChangePasswordForm';
import { scrollBarStylesOnBlack } from '@assets/theme/base/scrollbarStyles.jsx';
import HeaderChip from '@components/HeaderChip.jsx';
import { Box, Button, IconButton, Popover, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useTheme } from '@mui/material/styles';
import { IconEdit, IconKey, IconLogout, IconUser, IconX } from '@tabler/icons-react';
import { useAtom } from 'jotai';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

// ==============================|| PROFILE MENU ||============================== //
function ProfileSection() {
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const { t } = useTranslation();
    const [user] = useAtom(userAtom);

    const userInfo = {
        [t('name')]: user?.firstName + ' ' + user?.lastName,
        [t('email')]: user?.email,
        [t('phone')]: user?.phone,
        [t('company')]: user?.company,
        [t('key')]: user?.apiKey
    };

    const logoutFn = useLogoutFn();

    /**
     * anchorRef is used on different components and specifying one type leads to other components throwing an error
     * */
    const anchorRef = useRef(null);

    const handleClose = event => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen(prevOpen => !prevOpen);
    };

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    return (
        <>
            <HeaderChip
                Icon={IconUser}
                tooltip={t('showProfileInfos')}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup='true'
                onClick={handleToggle}
                ref={anchorRef}
            />
            <Popover
                anchorEl={anchorRef.current}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                elevation={4}
                onClose={handleClose}
                open={open}
                slotProps={{
                    paper: {
                        sx: {
                            p: 0,
                            borderRadius: theme.borders.borderRadius.xl,
                            background: theme.palette.primary.main,
                            overflow: 'hidden'
                        }
                    }
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
            >
                <Box
                    sx={{
                        maxHeight: { xs: 'calc(100vh - 28px)', sm: 'calc(100vh - 65px)' },
                        p: 3.5,
                        pb: 2.5,
                        width: { xs: 'calc(100vw - 28px)', sm: '430px' },
                        color: '#fff',
                        overflow: 'auto',
                        ...scrollBarStylesOnBlack
                    }}
                >
                    <IconButton
                        aria-label={t('closeProfile')}
                        onClick={handleClose}
                        size='large'
                        sx={{
                            'outlineOffset': '-4px',
                            'position': 'absolute',
                            'top': '15px',
                            'right': '15px',
                            'zIndex': 5,
                            '& svg': {
                                width: '1.5rem !important',
                                height: '1.5rem !important'
                            }
                        }}
                        title={t('closeProfile')}
                    >
                        <IconX color={theme.palette.secondary.main} />
                    </IconButton>

                    <Typography color='secondary' component='h3' variant='h4'>
                        {t('profileHeader')}
                    </Typography>

                    <Grid
                        container
                        xs={12}
                        sx={{
                            my: 1
                        }}
                    >
                        {Object.entries(userInfo).map(([key, value], index) => (
                            <Grid
                                key={key}
                                xs={12}
                                sx={{
                                    my: 1
                                }}
                            >
                                <Grid
                                    container
                                    direction={{
                                        xs: 'column',
                                        sm: 'row'
                                    }}
                                    key={index}
                                    sx={{
                                        alignItems: { xs: 'flex-start', sm: 'center' },
                                        flexWrap: 'nowrap',

                                        gap: {
                                            xs: 0,
                                            sm: 6
                                        },

                                        justifyContent: 'space-between'
                                    }}
                                >
                                    <Grid
                                        sx={{
                                            fontWeight: 300,
                                            fontSize: '0.93rem',
                                            flexShrink: 0,
                                            whiteSpace: 'no-wrap'
                                        }}
                                    >
                                        {key}
                                    </Grid>

                                    <Grid
                                        sx={{
                                            fontWeight: 500,
                                            fontSize: '0.96rem',
                                            textAlign: { xs: 'right', sm: 'left' },
                                            maxWidth: '232px',
                                            wordWrap: 'break-word'
                                        }}
                                    >
                                        {value || ' - '}
                                    </Grid>
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>

                    <UserDialog
                        hideOrgAdminToggle
                        title={t('editProfile')}
                        userData={user}
                        activatorProps={{
                            startIcon: <IconEdit />,
                            sx: { mt: 3, outlineOffset: '-3px' },
                            variant: 'outlined',
                            color: 'white',
                            fullWidth: true
                        }}
                        activatorText={t('editProfile')}
                        maxWidth='md'
                    />

                    <DynamicDialog
                        title={t('changePassword')}
                        activatorText={t('changePassword')}
                        activatorProps={{
                            variant: 'outlined',
                            color: 'white',
                            startIcon: <IconKey />,
                            fullWidth: true,
                            sx: { mt: 2, outlineOffset: '-3px' }
                        }}
                        hideActions
                    >
                        {({ handleCancel }) => (
                            <Box
                                sx={{
                                    my: 2.5,
                                    mx: 1
                                }}
                            >
                                <ChangePasswordForm onCancel={handleCancel} />
                            </Box>
                        )}
                    </DynamicDialog>

                    <Button
                        color='secondary'
                        fullWidth
                        onClick={logoutFn}
                        startIcon={<IconLogout />}
                        sx={{ my: 2, outlineOffset: '-3px' }}
                        variant='outlined'
                    >
                        {t('logOutSB')}
                    </Button>

                    <Box
                        sx={{
                            color: '#eee',
                            fontSize: '0.8rem',
                            textAlign: 'center',
                            width: '100%'
                        }}
                    >
                        Version {/* eslint-disable-line */ __APP_VERSION__}
                    </Box>
                </Box>
            </Popover>
        </>
    );
}

export default ProfileSection;

import typography from '@assets/theme/base/typography';
import pxToRem from '@assets/theme/functions/pxToRem';

const { size, fontWeightRegular } = typography;

const tab = {
    defaultProps: {
        disableRipple: true,
        disableFocusRipple: true
    },
    styleOverrides: {
        root: ({ theme }) => ({
            'backgroundColor': 'transparent' + '!important',
            'alignItems': 'flex-center',
            'minWidth': '130px !important',
            'fontSize': size.md,
            'fontWeight': fontWeightRegular,
            'textTransform': 'none',
            'lineHeight': 'inherit',
            'border': 'none',
            'color': '#000',
            'opacity': '1 !important',
            'borderTopLeftRadius': '28px !important',
            'borderTopRightRadius': '28px !important',

            ':focus, :hover': {
                outline: '3px solid #000 !important',
                outlineOffset: '-2px',
                zIndex: 2
            },

            '& .material-icons, .material-icons-round': {
                marginBottom: '0 !important',
                marginRight: pxToRem(6)
            },

            '& svg': {
                marginBottom: '0 !important',
                marginRight: pxToRem(6)
            },

            '&.Mui-selected': {
                border: '1.5px solid #333',
                borderBottom: 'none',
                color: '#000 !important',
                backgroundColor: `rgba(${theme.vars.palette.secondary.mainChannel} / 0.85) !important`,
                fontWeight: 700
            }
        }),

        labelIcon: {
            paddingTop: pxToRem(4)
        }
    }
};

export default tab;

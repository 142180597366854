import { Autocomplete, Box, TextField } from '@mui/material';
import { useMemo, useState } from 'react';
import JiraUserInfo from './JiraUserInfo';
import { useTranslation } from 'react-i18next';

export default function JiraUsersAutocomplete({ defaultUser, users, handleSelection }) {
    const [inputValue, setInputValue] = useState('');
    const { t } = useTranslation();

    let sortedUsers = useMemo(() => {
        if (!users) return [];
        return users?.sort((a, b) => a?.displayName?.localeCompare(b?.displayName));
    }, [users]);

    const filteredUsers = useMemo(() => {
        if (!inputValue) return [];

        let filtered = sortedUsers.filter(_user =>
            _user.displayName.toLowerCase().includes(inputValue.toLowerCase())
        );

        // show not more than 20 entries
        filtered = filtered.slice(0, 50);

        return filtered;
    }, [inputValue, sortedUsers]);

    if (sortedUsers.length < 100)
        return (
            <Autocomplete
                id='jira-users-list'
                isOptionEqualToValue={(option, value) => {
                    return option.accountId === value.accountId;
                }}
                sx={{ width: 280 }}
                options={sortedUsers}
                defaultValue={defaultUser}
                autoHighlight
                getOptionLabel={option => option?.displayName}
                onChange={handleSelection}
                renderOption={(props, option) => (
                    <Box component='li' {...props} key={option.accountId}>
                        <JiraUserInfo user={option} />
                    </Box>
                )}
                renderInput={params => (
                    <TextField
                        {...params}
                        variant='standard'
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'jira-user' // disable autocomplete and autofill
                        }}
                        sx={{
                            borderRadius: 1
                        }}
                    />
                )}
                filterOptions={(options, params) => {
                    options = options || [];
                    const _inputValue = params.inputValue.trim().toLowerCase();

                    if (_inputValue === '') return options;

                    const filtered = options.filter(option =>
                        option.displayName.toLowerCase().includes(_inputValue)
                    );

                    return filtered;
                }}
            />
        );

    return (
        <Autocomplete
            sx={{ width: 280 }}
            isOptionEqualToValue={(option, value) => {
                return option.accountId === value.accountId;
            }}
            filterOptions={x => x}
            renderOption={(props, option) => (
                <Box component='li' {...props} key={option.accountId}>
                    <JiraUserInfo user={option} />
                </Box>
            )}
            renderInput={params => (
                <TextField
                    {...params}
                    variant='standard'
                    slotProps={{
                        htmlInput: {
                            ...params.inputProps,
                            autoComplete: 'jira-user' // disable autocomplete and autofill
                        }
                    }}
                    sx={{
                        borderRadius: 1
                    }}
                />
            )}
            includeInputInList
            filterSelectedOptions
            blurOnSelect
            autoComplete
            noOptionsText={
                !inputValue || defaultUser?.displayName === inputValue
                    ? t('typeToSearchUser')
                    : t('noResultsFound')
            }
            value={defaultUser}
            onChange={handleSelection}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue.toLowerCase());
            }}
            options={filteredUsers}
            autoHighlight
            getOptionLabel={option => option?.displayName}
        />
    );
}

import '@assets/globalStyles.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './i18n';

window.global = window.global || window;

ReactDOM.createRoot(document.getElementById('app')).render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);

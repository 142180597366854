import { demoModeAtom } from '@/global-store.jsx';
import { Box } from '@mui/material';
import { useAtom } from 'jotai';
import NavBadge from '../NavBadge';

// ==============================|| MAIN LOGO ||============================== //
function LogoSection({ dark }) {
    const [demoMode] = useAtom(demoModeAtom);

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                whiteSpace: 'nowrap'
            }}
        >
            <Box
                aria-hidden='true'
                component='img'
                src={
                    dark
                        ? 'https://assets.eye-able.com/dashboard-assets/icons/Icon_Dashboard_eye-able_black.svg'
                        : 'https://assets.eye-able.com/dashboard-assets/icons/Icon_Dashboard_eye-able_white.svg'
                }
                sx={{
                    'ml': { xs: -0.5, md: -1 },
                    'width': '3.9rem',
                    'bgcolor': 'transparent',
                    '@media (max-width: 1320px)': {
                        width: '3.25rem'
                    },
                    '@media (max-width: 290px)': {
                        width: '2.6rem'
                    }
                }}
            />
            <Box
                sx={[
                    {
                        'whiteSpace': 'nowrap',
                        'position': 'relative',
                        'fontSize': '1.15rem',
                        'mr': 3,

                        '@media (max-width: 290px)': {
                            fontSize: '0.9rem'
                        }
                    },
                    dark
                        ? {
                              color: '#000'
                          }
                        : {
                              color: '#fff'
                          }
                ]}
            >
                <b>Eye-Able</b>

                <span style={{ position: 'absolute', right: '-12px', top: '-4px' }}>&#174;</span>
            </Box>
            {demoMode ? <NavBadge text='demo' /> : null}
        </Box>
    );
}

export default LogoSection;

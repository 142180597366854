const primary = {
    main: '#121212',
    focus: '#E6E6E6',
    lightGrey: '#E6E6E6',
    light: '#555',
    darkGrey: '#555',
    white: '#fff'
};

const secondary = {
    main: '#FFD050',
    light: '#FFDD80',
    dark: '#ffbb2c',
    darker: '#eaa511',
    pastel: '#ffebb3',
    white: '#FFF8E6'
};

// export const buttonGradient = "linear-gradient(60deg, #121212 25%, #FFD050 100%)";

const colors = {
    background: {
        default: '#000',
        main: primary.main,
        white: primary.white
    },

    transparent: {
        main: 'rgba(0, 0, 0, 0)',
        default: 'rgba(0, 0, 0, 0)',
        focus: 'rgba(0, 0, 0, 0)'
    },

    confirm: {
        main: '#8d009b',
        focus: '#700073'
    },

    white: {
        main: '#ffffff',
        focus: '#dddddd'
    },

    black: {
        light: '#222',
        main: '#000000',
        focus: '#444'
    },

    text: {
        main: '#000',
        secondary: '#333'
    },

    primary: primary,

    secondary: secondary,

    info: {
        main: '#0d31e5',
        focus: '#0e2f83'
    },

    success: {
        main: '#28982c',
        focus: '#4a7c4c'
    },

    warning: {
        main: '#fda605',
        focus: '#fc9d26'
    },

    error: {
        main: '#c50400',
        focus: '#f65f53'
    },

    darkGreen: {
        main: '#006700',
        focus: '#004a00'
    },

    darkRed: {
        main: '#b10000',
        focus: '#8f0000'
    },

    light: {
        main: '#fafafa',
        focus: '#dddddd'
    },

    dark: primary,

    yellow: secondary,

    coloredShadows: {
        primary: primary.darkGrey,
        secondary: secondary.light,
        info: 'rgba(18, 21, 154,0.15)',
        success: '#4caf4f',
        warning: '#ff9900',
        error: '#f44336',
        light: '#adb5bd',
        dark: primary.darkGrey
    },

    tabs: {
        indicator: { boxShadow: '#ddd' }
    }
};

export default colors;

import LanguageSelect from '@components/LanguageSelect.jsx';
import NotificationSection from '@layout/Header/NotificationSection.jsx';
import { Box, IconButton } from '@mui/material';
//import NotificationSection from './NotificationSection';
import HeaderChip from '@components/HeaderChip.jsx';
import { IconAccessible, IconLogout, IconMenu2 } from '@tabler/icons-react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import LogoSection from './LogoSection.jsx';
//import SearchSection from './SearchSection';
import { demoModeAtom } from '@/global-store.jsx';
import useLogoutFn from '@/hooks/useLogoutFn.jsx';
import { useAtom } from 'jotai';
import { useEffect } from 'react';
import ProfileSection from './ProfileSection.jsx';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //
function Header({ handleLeftDrawerToggle }) {
    const { t } = useTranslation();
    const [demoMode] = useAtom(demoModeAtom);

    const logoutFn = useLogoutFn();

    function openAssist() {
        try {
            window.EyeAbleAPI?.toggleToolbar();
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        function logoutOtherTabs(event) {
            if (event.key === 'logout') logoutFn(true);
        }

        window.addEventListener('storage', logoutOtherTabs);

        return () => {
            window.removeEventListener('storage', logoutOtherTabs);
        };
    }, [logoutFn]);

    return (
        <>
            <Box
                sx={{
                    width: 'auto',
                    display: 'flex',
                    alignItems: 'center'
                }}
            >
                {handleLeftDrawerToggle ? (
                    <IconButton
                        id='drawer-toggle'
                        aria-label='menu'
                        onClick={handleLeftDrawerToggle}
                        sx={{
                            p: 1,
                            display: { xs: 'flex', xl: 'none' },
                            mr: { xs: 0.4, md: 0.8 },
                            ml: -1
                        }}
                    >
                        <IconMenu2 color='#fff' size='1.65rem' stroke={2.25} />
                    </IconButton>
                ) : null}

                <LogoSection />
            </Box>
            <Box component='nav' aria-label={t('topNavAria')} id='header-right-section'>
                <Box
                    component='ul'
                    sx={{
                        pl: 1,
                        width: 'auto',
                        display: 'flex',
                        alignItems: 'center',
                        gap: { xs: 0.75, md: 1.25, lg: 1.75 },
                        listStyle: 'none'
                    }}
                >
                    <Box component='li'>
                        <HeaderChip
                            Icon={IconAccessible}
                            tooltip={t('assistButton')}
                            id='eyeAble_customToolOpenerID'
                            onClick={openAssist}
                        />
                    </Box>

                    <Box
                        component='li'
                        sx={{
                            display: { xs: 'none', sm: 'block' }
                        }}
                    >
                        <LanguageSelect />
                    </Box>

                    <Box
                        component='li'
                        sx={{
                            display: { xs: 'none', sm: 'block' }
                        }}
                    >
                        <NotificationSection />
                    </Box>

                    <Box
                        component='li'
                        sx={{
                            display: { xs: 'none', sm: 'block' }
                        }}
                    >
                        {demoMode ? (
                            <HeaderChip
                                Icon={IconLogout}
                                tooltip={t('exit')}
                                color='warning'
                                onClick={logoutFn}
                                text={t('exit')}
                            />
                        ) : (
                            <ProfileSection />
                        )}
                    </Box>
                </Box>
            </Box>
        </>
    );
}

Header.propTypes = {
    handleLeftDrawerToggle: PropTypes.func
};

export default Header;

import { availableAuditEntriesAtom } from '@/global-store';
import { Autocomplete, Box, TextField } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useAtom } from 'jotai';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

/**
 * DomainSelect component
 * @param onChange - custom change handler, will disable reloading and does not save the domain to the global store
 * @param sx - sx style object
 * @returns {JSX.Element}
 * @constructor
 */
function DomainSelect({
    initialEntryId,
    onChange,
    width = '100%',
    size = 'standard',
    borderRadius = 1,
    sx = {}
}) {
    const [availableAuditEntries] = useAtom(availableAuditEntriesAtom);
    const [tempCurrentAuditEntryId, setTempCurrentAuditEntryId] = useState(initialEntryId);
    const [inputValue, setInputValue] = useState('');

    const { t } = useTranslation();

    const domains = useMemo(() => {
        if (!availableAuditEntries) return [];
        const _domains = [];

        const countMap = {};

        for (const entry of availableAuditEntries) {
            let label = entry.domain;

            if (availableAuditEntries.length < 99) {
                // show the full domain for admins, for normal users remove the protocol and the trailing slash
                if (label.startsWith('http://') || label.startsWith('https://')) {
                    label = label.replace(/^(http|https):\/\//, '');
                }

                if (label.endsWith('/')) {
                    label = label.slice(0, -1);
                }
            }

            if (countMap[label]) {
                countMap[label]++;
                label = `${label} (${countMap[label]})`;
            } else {
                countMap[label] = 1;
            }

            _domains.push({
                id: entry.id,
                label
            });
        }

        return _domains;
    }, [availableAuditEntries]);

    const currentEntry = useMemo(
        () => domains.find(domain => domain.id === tempCurrentAuditEntryId) || null,
        [domains, tempCurrentAuditEntryId]
    );

    const filteredDomains = useMemo(() => {
        if (!inputValue) return [];

        let filtered = domains.filter(domain => domain.label.toLowerCase().includes(inputValue));

        // show not more than 7 entries
        filtered = filtered.slice(0, 7);

        if (
            currentEntry?.id &&
            filtered.length &&
            !filtered.find(domain => domain.id === currentEntry.id)
        ) {
            filtered.push(currentEntry);
        }

        return filtered;
    }, [inputValue, domains, currentEntry]);

    function domainSelected(entry) {
        if (!entry?.id) return;

        setTempCurrentAuditEntryId(entry.id);

        // if the onChange prop is set, we notify the parent component about the change
        onChange?.(availableAuditEntries.find(auditEntry => auditEntry.id === entry.id));
    }

    if (!domains?.length) return <></>;

    return (
        <Grid
            container
            sx={{
                gap: 1.25,
                alignItems: 'center',
                flexWrap: 'nowrap',
                width: { xs: '100%', sm: width },
                minWidth: 180,
                ...sx
            }}
        >
            {domains?.length < 2 ? (
                <Box
                    sx={{
                        fontWeight: 500,
                        padding: '7.5px 12px',
                        border: '1.75px solid rgba(18, 18, 18, 0.5)',
                        borderRadius: borderRadius
                    }}
                >
                    Domain: <b>{currentEntry?.label || ' - '}</b>
                </Box>
            ) : domains.length > 99 ? (
                <Autocomplete
                    fullWidth
                    filterOptions={x => x}
                    renderInput={params => (
                        <TextField
                            {...params}
                            size={size}
                            variant='outlined'
                            fullWidth
                            label='Domain'
                            sx={{
                                backgroundColor: 'rgba(255,255,255,0.33)',
                                borderRadius: borderRadius
                            }}
                        />
                    )}
                    sx={{
                        '& fieldset': {
                            borderRadius: borderRadius
                        }
                    }}
                    includeInputInList
                    filterSelectedOptions
                    disableClearable
                    blurOnSelect
                    autoComplete
                    noOptionsText={
                        !inputValue || currentEntry?.label === inputValue
                            ? t('typeToSearchDomain')
                            : t('noResultsFound')
                    }
                    value={currentEntry}
                    onChange={(event, newValue) => domainSelected(newValue)}
                    onInputChange={(event, newInputValue) => {
                        setInputValue(newInputValue.toLowerCase());
                    }}
                    options={filteredDomains}
                    getOptionLabel={option => option?.label || ''}
                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                />
            ) : (
                <Autocomplete
                    fullWidth
                    noOptionsText={t('noResultsFound')}
                    renderInput={params => (
                        <TextField
                            {...params}
                            size={size}
                            variant='outlined'
                            fullWidth
                            label='Domain'
                            sx={{
                                backgroundColor: 'rgba(255,255,255,0.33)',
                                borderRadius: borderRadius
                            }}
                        />
                    )}
                    sx={{
                        '& fieldset': {
                            borderRadius: borderRadius
                        }
                    }}
                    disableClearable
                    blurOnSelect
                    autoComplete
                    autoHighlight
                    openOnFocus
                    value={currentEntry}
                    onChange={(event, newValue) => domainSelected(newValue)}
                    options={domains}
                    getOptionLabel={option => option?.label}
                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                />
            )}
        </Grid>
    );
}

export default DomainSelect;

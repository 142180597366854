import borders from '@assets/theme/base/borders';
import boxShadows from '@assets/theme/base/boxShadows';
import breakpoints from '@assets/theme/base/breakpoints';
import colors from '@assets/theme/base/colors';
import { scrollBarStylesOnWhite } from '@assets/theme/base/scrollbarStyles.jsx';
import typography from '@assets/theme/base/typography';
import appBar from '@assets/theme/components/appBar';
import avatar from '@assets/theme/components/avatar';
import button from '@assets/theme/components/button';
import buttonBase from '@assets/theme/components/buttonBase';
import card from '@assets/theme/components/card';
import cardContent from '@assets/theme/components/card/cardContent';
import cardMedia from '@assets/theme/components/card/cardMedia';
import link from '@assets/theme/components/link';
import menu from '@assets/theme/components/menu';
import menuItem from '@assets/theme/components/menu/menuItem';
import slider from '@assets/theme/components/slider';
import stepper from '@assets/theme/components/stepper';
import stepConnector from '@assets/theme/components/stepper/stepConnector';
import stepLabel from '@assets/theme/components/stepper/stepLabel';
import tabs from '@assets/theme/components/tabs';
import tab from '@assets/theme/components/tabs/tab';
import tooltip from '@assets/theme/components/tooltip';
import boxShadow from '@assets/theme/functions/boxShadow';
import hexToRgb from '@assets/theme/functions/hexToRgb';
import linearGradient from '@assets/theme/functions/linearGradient';
import pxToRem from '@assets/theme/functions/pxToRem';
import rgba from '@assets/theme/functions/rgba';
import { createTheme } from '@mui/material/styles';
import { IconChevronDown } from '@tabler/icons-react';

const theme = createTheme({
    contrastThreshold: 4.5,
    cssVariables: true,
    breakpoints: breakpoints,
    palette: colors,
    typography: typography,
    boxShadows: boxShadows,
    borders: borders,
    functions: {
        boxShadow,
        hexToRgb,
        linearGradient,
        pxToRem,
        rgba
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                //...globals
            }
        },
        MuiUseMediaQuery: {
            defaultProps: {
                noSsr: true
            }
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    '& .MuiInputBase-input:-webkit-autofill': {
                        ' -webkit-box-shadow':
                            '0 0 0 100px rgba(255, 224, 7, 0.5) inset !important',
                        'WebkitTextFillColor': '#000 !important',
                        'caretColor': '#000 !important'
                    },

                    '& svg': {
                        color: 'rgba(0,0,0,0.67) !important'
                    }
                },
                input: {
                    '&::placeholder': {
                        color: '#000 !important',
                        fontWeight: '400 !important',
                        opacity: '0.7 !important'
                    },
                    '&::-webkit-input-placeholder': {
                        color: '#000 !important',
                        opacity: '0.7 !important'
                    },
                    '&::-moz-placeholder': {
                        color: '#000 !important',
                        opacity: '0.7 !important'
                    },
                    '&:-ms-input-placeholder': {
                        color: '#000 !important',
                        opacity: '0.7 !important'
                    }
                }
            }
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    '&:not(.MuiChipsInput-TextField) .Mui-focused input:not(.MuiAutocomplete-input), & .MuiInputBase-root.Mui-focused':
                        {
                            outline: `3px solid ${colors.secondary.dark}`,
                            borderRadius: '4px'
                        },

                    '& label': {
                        // prevents the outline from overlapping the label
                        paddingLeft: '5px',
                        paddingRight: '10px',
                        marginLeft: '-5px',
                        marginRight: '-10px',
                        color: `${colors.primary.main}`
                    },

                    '& label.Mui-focused, & .Mui-focused label': {
                        color: `${colors.black.main}`,
                        background: 'linear-gradient(to top, #fff 65%, transparent 65%)'
                    }
                }
            }
        },
        MuiTextField: {
            defaultProps: {
                variant: 'outlined'
            },
            styleOverrides: {
                root: {
                    '& fieldset': {
                        borderWidth: '1.5px !important',
                        borderColor: 'rgba(18, 18, 18, 0.5)'
                    }
                },
                sizeSmall: {
                    '& fieldset': {
                        borderWidth: '1.5px !important',
                        borderColor: 'rgba(18, 18, 18, 0.5)'
                    }
                },
                sizeLarge: {
                    '& fieldset': {
                        borderWidth: '2.5px !important',
                        borderColor: 'rgba(18, 18, 18, 0.5)'
                    }
                }
            }
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    fontSize: '0.88em !important',
                    color: '#000 !important'
                }
            }
        },
        MuiList: {
            styleOverrides: {
                root: {
                    '& .MuiButtonBase-root.MuiMenuItem-root': {
                        margin: '3px 0',
                        padding: '7px'
                    },

                    '& .MuiListSubheader-sticky': {
                        top: '-8px',
                        background: colors.light.main
                    },
                    'backgroundColor': 'transparent !important',
                    ...scrollBarStylesOnWhite
                }
            }
        },
        MuiButtonBase: buttonBase,
        MuiSwitch: {
            styleOverrides: {
                root: {
                    '& :hover, & .Mui-focusVisible': {
                        outlineOffset: '-3.5px'
                    },
                    '& .Mui-focusVisible': {
                        outline: `3px solid ${colors.secondary.dark}`
                    }
                }
            }
        },
        MuiButton: button,
        MuiIconButton: {
            defaultProps: {
                color: 'text'
            },
            styleOverrides: {
                root: {
                    '& .tabler-icon': {
                        fontSize: pxToRem(17),
                        width: pxToRem(17),
                        height: pxToRem(17)
                    }
                }
            }
        },
        MuiToggleButton: {
            ...button,
            styleOverrides: {
                ...button.styleOverrides,
                root: {
                    ...button.styleOverrides.root,
                    'borderColor': colors.black.main,
                    'color': colors.black.main,
                    '&.Mui-selected': {
                        'color': colors.secondary.main,
                        'backgroundColor': colors.black.main,
                        '&:hover': {
                            backgroundColor: colors.secondary.main,
                            color: colors.black.main
                        }
                    },
                    '&:hover': {
                        backgroundColor: colors.secondary.main,
                        color: colors.black.main
                    }
                }
            }
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    borderColor: colors.black.light
                }
            }
        },
        MuiCard: card,
        MuiCardMedia: cardMedia,
        MuiCardContent: cardContent,
        MuiMenu: menu,
        MuiMenuItem: menuItem,
        MuiSlider: slider,
        MuiAvatar: avatar,
        MuiTooltip: tooltip,
        MuiAppBar: appBar,
        MuiTabs: tabs,
        MuiTab: tab,
        MuiStepper: stepper,
        MuiStepConnector: stepConnector,
        MuiStepLabel: stepLabel,
        MuiLink: link,
        MuiSelect: {
            styleOverrides: {
                root: {
                    '& fieldset': {
                        borderWidth: '1.5px !important',
                        borderColor: 'rgba(18, 18, 18, 0.5) !important'
                    }
                    /* '&:focus > div, &:hover > div': {
                        outline: `3px solid ${colors.secondary.dark} !important`
                    },
                    '& input:focus': {
                        outline: `3px solid ${colors.secondary.dark} !important`
                    } */
                },
                sizeSmall: {
                    '& fieldset': {
                        borderWidth: '1.5px !important',
                        borderColor: 'rgba(18, 18, 18, 0.5) !important'
                    }
                },
                sizeLarge: {
                    '& fieldset': {
                        borderWidth: '2.5px !important',
                        borderColor: 'rgba(18, 18, 18, 0.5) !important'
                    }
                }
            }
        },
        MuiAutocomplete: {
            styleOverrides: {
                root: {
                    '& fieldset': {
                        borderWidth: '1.5px !important',
                        borderColor: 'rgba(18, 18, 18, 0.5) !important'
                    },
                    '& .MuiAutocomplete-option': {
                        breakWord: 'break-all'
                    }
                },
                sizeSmall: {
                    '& fieldset': {
                        borderWidth: '1.5px !important',
                        borderColor: 'rgba(18, 18, 18, 0.5) !important'
                    }
                },
                sizeLarge: {
                    '& fieldset': {
                        borderWidth: '2.5px !important',
                        borderColor: 'rgba(18, 18, 18, 0.5) !important'
                    }
                }
            }
        },
        MuiBox: {
            styleOverrides: {
                root: {
                    ...scrollBarStylesOnWhite
                }
            }
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    ...scrollBarStylesOnWhite
                }
            }
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    borderRadius: borders.borderRadius.xl,
                    boxShadow: boxShadows.xxl
                },

                paperFullScreen: {
                    borderRadius: 0
                }
            }
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    ...scrollBarStylesOnWhite
                }
            }
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    '&[data-shrink=true]': {
                        color: '#000 !important'
                    }
                }
            }
        },
        MuiAccordion: {
            styleOverrides: {
                root: {
                    borderRadius: '8px !important',
                    border: '1.5px solid #555'
                }
            }
        },
        MuiAccordionSummary: {
            styleOverrides: {
                root: {
                    '& svg': {
                        color: colors.black.main
                    },
                    ':hover, :focus': {
                        outlineOffset: '-1px'
                    },
                    '& .MuiAccordionSummary-content': {
                        marginRight: '10px !important',
                        display: 'flex',
                        alignItems: 'center'
                    }
                }
            },
            defaultProps: {
                expandIcon: <IconChevronDown />,
                sx: {
                    borderRadius: '8px !important',
                    fontWeight: 600,
                    py: 0.5
                }
            }
        }
    }
});

export default theme;

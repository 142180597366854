import { currentAuditEntryIdAtom, reportDemoModeAtom } from '@/global-store';
import { useAtom } from 'jotai';
import { useLocation } from 'react-router-dom';
import DomainSelect from './DomainSelect';

/**
 * GlobalDomainSelect component
 * @param onChange - custom change handler, will disable reloading and does not save the domain to the global store
 * @param sx - sx style object
 * @returns {JSX.Element}
 * @constructor
 */
function GlobalDomainSelect({ sx = {} }) {
    const [currentAuditEntryId, setCurrentAuditEntryId] = useAtom(currentAuditEntryIdAtom);
    const [reportDemoMode] = useAtom(reportDemoModeAtom);

    const location = useLocation();
    const path = location.pathname.endsWith('/')
        ? location.pathname.slice(0, -1)
        : location.pathname;

    function domainSelected(entry) {
        console.log('domainSelected', entry, currentAuditEntryId);

        const id = entry?.id;
        if (!id) return;
        if (id === currentAuditEntryId) return;

        setCurrentAuditEntryId(id);
        let newPath;

        if (path.includes('/report/issues/issue')) {
            newPath = '/report/issues';
        } else if (path.includes('/report/pages/page')) {
            newPath = '/report/pages';
        } else if (path.includes('/report/analysis/lighthouse/page')) {
            newPath = '/report/analysis/lighthouse/';
        } else if (path.includes('/upload/pdf/')) {
            newPath = '/report/pdfs/upload';
        } else if (path.includes('/pdf/')) {
            newPath = '/report/pdfs/';
        }

        if (newPath) {
            return window.location.replace(newPath);
        }
    }

    if (reportDemoMode) return <b>Demo Domain</b>;

    return (
        <DomainSelect
            initialEntryId={currentAuditEntryId}
            onChange={domainSelected}
            size='small'
            width='340px'
            borderRadius={2}
            sx={sx}
        />
    );
}

export default GlobalDomainSelect;

import { defaultMyGoalsBoardFilters } from '@helpers/constants';
import { demoUser, reportStorageUrl } from '@http/constants';
import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

// UI
export const leftDrawerOpenAtom = atom(false);
export const activeNavItemAtom = atom('');
export const toastsAtom = atom([]); // used by useNotification

let isDemo = false;

if (window.location.pathname === '/demo') {
    localStorage.setItem('demoMode', 'true');
    isDemo = true;
} else {
    localStorage.setItem('demoMode', 'false');
}

// Demo Mode for the whole dashboard, has the demo user logged in
export const demoModeAtom = atomWithStorage('demoMode', isDemo);

// get values with fallback from localStorage
const localValues = {
    rerenderLayout: localStorage.getItem('rerenderLayout') || new Date().getTime().toString(),
    currentAuditEntryId: localStorage.getItem('currentAuditEntryId') || '',
    tokenExpires: localStorage.getItem('tokenExpires') || 0,
    websiteLicenses: localStorage.getItem('websiteLicenses') || '[]',
    checkerLicenses: localStorage.getItem('checkerLicenses') || '[]',
    selectedAssistDomain: localStorage.getItem('selectedAssistDomain') || '',
    selectedGraphTimeRange: localStorage.getItem('selectedGraphTimeRange') || '"twoWeeks"',
    cookieBannerAccepted: localStorage.getItem('cookieBannerAccepted') || 'false',
    versionOfLastNotificationSeen:
        localStorage.getItem('versionOfLastNotificationSeen') || '"1.0.0"',
    notificationLastViewDate: localStorage.getItem('notificationLastViewDate') || 0,
    userToursSeen: localStorage.getItem('userToursSeen') || '[]',
    myGoalsBoardFilters:
        localStorage.getItem('defaultMyGoalsBoardFilters') ||
        JSON.stringify(defaultMyGoalsBoardFilters),
    reactTableSorting: localStorage.getItem('reactTableSorting') || '{}',
    reactTableFilters: localStorage.getItem('reactTableFilters') || '{}',
    //'X-Auth-Dev': localStorage.getItem('X-Auth-Dev') || '',
    spellCheckColumnSorting: localStorage.getItem('spellCheckColumnSorting') || '[]',
    spellCheckSitesColumnSorting: localStorage.getItem('spellCheckSitesColumnSorting') || '[]',
    spellCheckDictColumnSorting: localStorage.getItem('spellCheckDictColumnSorting') || '[]',
    spellCheckSiteDetailColumnSorting:
        localStorage.getItem('spellCheckSiteDetailColumnSorting') || '[]',
    spellCheckColumnFilters: localStorage.getItem('spellCheckColumnFilters') || '[]',
    spellCheckSitesColumnFilters: localStorage.getItem('spellCheckSitesColumnFilters') || '[]',
    spellCheckDictColumnFilters: localStorage.getItem('spellCheckDictColumnFilters') || '[]',
    spellCheckSiteDetailColumnFilters:
        localStorage.getItem('spellCheckSiteDetailColumnFilters') || '[]'
};

// check if all localValues are valid JSON
for (const key in localValues) {
    try {
        if (localValues[key]) localValues[key] = JSON.parse(localValues[key]);
    } catch (error) {
        console.error('Invalid JSON in store!', error, key);

        localStorage.clear();
        sessionStorage.clear();
        window.location.replace('/authentication/login');

        break;
    }
}

export const tokenExpiresAtom = atomWithStorage('tokenExpires', localValues.tokenExpires);

export const rerenderLayoutAtom = atomWithStorage('rerenderLayout', localValues.rerenderLayout);

// Demo Modes for specific products, only true if user is logged in but has no license for the product.
// Always true when in general demo mode. Otherwise it may be set by the license query
export const reportDemoModeAtom = atom(isDemo);
export const auditDemoModeAtom = atom(isDemo);
export const assistDemoModeAtom = atom(isDemo);
export const translateDemoModeAtom = atom(isDemo);
export const easyDemoModeAtom = atom(isDemo);

// Current User
export const userAtom = atom(isDemo ? demoUser : undefined);

// X-Auth-Dev token for development on local machine
//export const devTokenAtom = import.meta.env.PROD ? atom(null) : atomWithStorage('X-Auth-Dev', localStorage.getItem('X-Auth-Dev'));

export const assistDomainAtom = atomWithStorage(
    'selectedAssistDomain',
    localValues.selectedAssistDomain
);

export const auditLicensesAtom = atom([]);
export const assistLicensesAtom = atom([]);
export const availableAuditEntriesAtom = atom([]);
export const translateEasyLicenses = atom([]);
export const issuesTableFiltersAtom = atom([]);
export const bookmarksAtom = atom([]);
export const orgUsersAtom = atom([]);
export const todosDataAtom = atom({ todos: {} });
export const jiraConfigAtom = atom();
export const userNotificationsAtom = atom([]);
export const accessibilityDataAtom = atom();
export const primaryReportDateAtom = atom(null);
export const secondaryReportDateAtom = atom(null);

export const transSelectedDomainAtom = atomWithStorage('transSelectedDomain');

export const currentAuditEntryIdAtom = atomWithStorage(
    'currentAuditEntryId',
    localValues.currentAuditEntryId
);

export const currentAuditEntryAtom = atom(get => {
    const currentAuditEntryId = get(currentAuditEntryIdAtom);
    const availableAuditEntries = get(availableAuditEntriesAtom);

    if (!currentAuditEntryId || !availableAuditEntries?.length) return null;
    const foundAuditEntry = availableAuditEntries.find(entry => {
        return entry.id === currentAuditEntryId;
    });

    if (!foundAuditEntry) {
        alert('No audit entry found for id ' + currentAuditEntryId + '! Reloading page...');
        localStorage.setItem('currentAuditEntryId', availableAuditEntries[0]?.id || '');
        window.location.reload();
        return availableAuditEntries?.[0];
    }

    return foundAuditEntry;
});

export const selectedDomainAtom = atom(get => {
    const entry = get(currentAuditEntryAtom);

    if (!entry) return null;

    return entry.domain;
});

export const currentCdnDirectoryAtom = atom(get => {
    const currentAuditEntry = get(currentAuditEntryAtom);

    if (!currentAuditEntry) return null;

    return currentAuditEntry.cdnDirectory;
});

export const fullCdnPathAtom = atom(get => {
    const currentCdnDirectory = get(currentCdnDirectoryAtom);
    if (!currentCdnDirectory) {
        return null;
    }

    return reportStorageUrl + currentCdnDirectory + (currentCdnDirectory.endsWith('/') ? '' : '/');
});

export const fullUsersCdnPathAtom = atom(get => {
    const user = get(userAtom);
    if (!user) return null;

    return `${reportStorageUrl}Users/${user.id}/`;
});

export const selectedGraphTimeRangeAtom = atomWithStorage(
    'selectedGraphTimeRange',
    localValues.selectedGraphTimeRange
);

export const cookieBannerAcceptedAtom = atomWithStorage(
    'cookieBannerAccepted',
    localValues.cookieBannerAccepted
);

export const versionOfLastNotificationSeenAtom = atomWithStorage(
    'versionOfLastNotificationSeen',
    localValues.versionOfLastNotificationSeen
);

export const notificationLastViewDateAtom = atomWithStorage(
    'notificationLastViewDate',
    localValues.notificationLastViewDate
);

export const userToursSeenAtom = atomWithStorage('userToursSeen', localValues.userToursSeen);

export const reactTableFiltersAtom = atomWithStorage(
    'reactTableFilters',
    localValues.reactTableFilters
);

export const reactTableSortingAtom = atomWithStorage(
    'reactTableSorting',
    localValues.reactTableSorting
);

export const myGoalsFiltersAtom = atomWithStorage('defaultMyGoalsBoardFilters', {
    ...localValues.myGoalsBoardFilters,
    searchText: ''
});

export const spellCheckColumnSortingAtom = atomWithStorage(
    'spellCheckColumnSorting',
    localValues.spellCheckColumnSorting.length
        ? localValues.spellCheckColumnSorting
        : [{ id: 'spellcheckingOccurrences', desc: true }]
);

export const spellCheckSitesColumnSortingAtom = atomWithStorage(
    'spellCheckSitesColumnSorting',
    localValues.spellCheckSitesColumnSorting.length
        ? localValues.spellCheckSitesColumnSorting
        : [{ id: 'pageMistakesCount', desc: true }]
);

export const spellCheckSiteDetailColumnSortingAtom = atomWithStorage(
    'spellCheckSiteDetailColumnSorting',
    localValues.spellCheckSiteDetailColumnSorting.length
        ? localValues.spellCheckSiteDetailColumnSorting
        : [{ id: 'spellcheckingOccurrences', desc: true }]
);

export const spellCheckDictColumnSortingAtom = atomWithStorage(
    'spellCheckDictColumnSorting',
    localValues.spellCheckDictColumnSorting.length
        ? localValues.spellCheckDictColumnSorting
        : [{ id: 'spellcheckingOccurrences', desc: true }]
);

export const spellCheckColumnFiltersAtom = atomWithStorage(
    'spellCheckColumnFilters',
    localValues.spellCheckColumnFilters
);

export const spellCheckSitesColumnFiltersAtom = atomWithStorage(
    'spellCheckSitesColumnFilters',
    localValues.spellCheckSitesColumnFilters
);

export const spellCheckSiteDetailColumnFiltersAtom = atomWithStorage(
    'spellCheckSiteDetailColumnFilters',
    localValues.spellCheckSiteDetailColumnFilters
);

export const spellCheckDictColumnFiltersAtom = atomWithStorage(
    'spellCheckDictColumnFilters',
    localValues.spellCheckDictColumnFilters
);

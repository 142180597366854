import { Box } from '@mui/material';

export default function InputHelperText({ text, isError = false, sx = {}, id = '' }) {
    const textColor = isError ? '#a82424' : '#000';
    return (
        <Box
            sx={{ fontSize: '.8rem', color: textColor, marginTop: 1, textAlign: 'left', ...sx }}
            id={id}
        >
            {text}
        </Box>
    );
}

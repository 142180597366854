import OfferContactForm from '@components/OfferContactForm';
import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useTranslation } from 'react-i18next';

export default function DemoModeBanner({ product }) {
    const { t } = useTranslation();

    return (
        <Grid size={12}>
            <Box
                sx={{
                    bgcolor: 'secondary.main',
                    border: '4px solid #000',
                    borderRadius: 1.5,
                    mb: 2,
                    p: 2.5,
                    borderLeftWidth: '9px',
                    borderRightWidth: '9px'
                }}
            >
                <Typography
                    component='div'
                    variant='h2'
                    sx={{
                        mb: 2
                    }}
                >
                    {t('demoModeBoxTitle')} {product}!
                </Typography>

                <Typography
                    variant='body1'
                    sx={{
                        color: 'black.main',
                        fontWeight: 500,
                        mb: 2
                    }}
                >
                    {t('demoModeBoxText+' + product)}

                    <br />

                    {t('demoModeBoxCallToAction', { product })}
                </Typography>

                <OfferContactForm product={'Eye-Able® ' + product} showBookConsultation />
            </Box>
        </Grid>
    );
}

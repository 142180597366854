import Router from '@/Router';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Provider } from 'jotai';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: (retryCount, error) => {
                const status = error?.status;

                /* console.log(
                    'QueryClient retrying query after failure. retryCount:',
                    retryCount,
                    'status:',
                    status,
                    'error:',
                    error
                ); */

                // Check if the error is an instance of an Error and has a status of 401 (unauthorized) or 404 (not found)
                if (status === 401 || status === 404) {
                    // Do not retry
                    //console.log('Do not retry for 401 or 404 errors');
                    return false;
                }
                //retry up to 2 times for all other types of errors
                return retryCount < 2;
            },
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            refetchOnReconnect: false,
            refetchIntervalInBackground: 1000 * 60 * 60 * 4, // 4 hours
            refetchInterval: 1000 * 60 * 60 * 4, // 4 hours
            staleTime: 1000 * 60 * 60 * 4, // 4 hours
            cacheTime: 1000 * 60 * 60 * 4 // 4 hours
        }
    }
});

function App() {
    // const [readingDirection] = useAtom(readingDirectionAtom);

    // Setting the dir attribute for the body element
    // useEffect(() => {
    //   document.body.setAttribute('dir', readingDirection);
    //   console.log('update reading direction', readingDirection);
    // }, [readingDirection]);

    // i18n translations might still be loaded by the http backend
    // use react's Suspense

    return (
        <QueryClientProvider client={queryClient}>
            <Provider>
                <Router />
            </Provider>
        </QueryClientProvider>
    );
}

/*
function WrappedApp() {
  console.log('WrappedApp is rendered');
  return (
    <Suspense fallback={<BootScreen />}>
      <App />
    </Suspense>
  );
}
*/

export default App;

import { demoModeAtom, userAtom } from '@/global-store';
import clearStorageWithExeptions from '@/helpers/clearStorageWithExeptions';
import { api } from '@/http/constants';
import fetchWrapper from '@/http/fetchWrapper';
import { useAtom } from 'jotai';

export default function useLogoutFn() {
    const [demoMode] = useAtom(demoModeAtom);
    const [user] = useAtom(userAtom);

    async function handleLogOut(redirect = true) {
        console.log('Logging out. Production: ', import.meta.env.PROD, 'Demo mode: ', demoMode);
        //remove saved local and sessionStorage
        clearStorageWithExeptions();

        localStorage.removeItem('X-Dev-Token');

        if (!demoMode && import.meta.env.PROD && user) {
            await fetchWrapper.get(api.logout);
        }

        localStorage.setItem('demoMode', 'false');
        //setDemoMode(false);

        // Broadcast logout event to other tabs
        localStorage.setItem('logout', Date.now());

        //navigate('/'); no working because of the store, reloading the site will clean the store
        if (redirect) window.location.replace('/authentication/login');
        else window.location.reload();
    }

    return handleLogOut;
}

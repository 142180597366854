import { userToursSeenAtom } from '@/global-store.jsx';
import { useAtom } from 'jotai';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Joyride, { ACTIONS, LIFECYCLE, STATUS } from 'react-joyride';
import { useLocation } from 'react-router-dom';

/* const isElementInViewport = el => {
    const rect = el.getBoundingClientRect();
    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
};

const scrollToElement = el => {
    el.scrollIntoView({ behavior: 'smooth', block: 'center' });
}; */

// @param {boolean} [props.isIntroTour] - Optional. If true, the tour will be shown on all pages. THERE CAN ONLY BE ONE INTRO TOUR.
// @param {Object} props.containerRef - Optional. The ref of the container to scroll to.

function waitForScrollEnd() {
    let lastChangedFrame = 0;
    let lastX = window.scrollX;
    let lastY = window.scrollY;

    return new Promise(resolve => {
        function tick(frames) {
            if (frames >= 500 || frames - lastChangedFrame > 20) {
                resolve();
            } else {
                if (window.scrollX != lastX || window.scrollY != lastY) {
                    lastChangedFrame = frames;
                    lastX = window.scrollX;
                    lastY = window.scrollY;
                }
                requestAnimationFrame(tick.bind(null, frames + 1));
            }
        }
        tick(0);
    });
}

/**
 * UserTour component to guide users through a set of steps.
 * @param {Object} props - The props object.
 * @param {Object[]} props.steps - An array of steps for the tour.
 * @param {Object} [props.joyrideProps] - Optional. Props to pass to the Joyride component.
 */
function UserTour({ /*isIntroTour,  containerRef */ steps, joyrideProps }) {
    const { t } = useTranslation();

    const location = useLocation();

    const path = /*isIntroTour ? 'INTRO_TOUR' : */ location.pathname;

    const [userToursSeen, setUserToursSeen] = useAtom(userToursSeenAtom);

    const [runJoyride, setRunJoyride] = useState(false);

    useEffect(() => {
        /* const arrowDownHandler = e => {
            // Prevent Arrow Up from focusing elements outside the dialog
            if (runJoyride && (e.key === 'ArrowUp' || e.keyCode === 38)) {
                e.preventDefault();
            }
        }; */

        if (!userToursSeen?.includes(path)) {
            /* const handleKeyDown = e => {
            if (e.key === 'Tab' || e.keyCode === 9) {
                const buttons = document.querySelectorAll('.react-joyride__tooltip button');

                if (e.shiftKey) {
                    if (document.activeElement === buttons[0]) {
                        buttons[1].focus();
                        e.preventDefault();
                    }
                } else {
                    if (document.activeElement === buttons[1]) {
                        buttons[0].focus();
                        e.preventDefault();
                    }
                }
            }
        }; */

            // if this is not the intro tour and the intro tour has not been seen, don't run this tour
            //if (!isIntroTour && !userToursSeen?.includes('INTRO_TOUR')) return;

            setTimeout(() => {
                //document.body.style.overflow = 'hidden';
                window.scrollTo(0, 0);
                document.body.scrollTop = 0;
                document.documentElement.scrollTop = 0;

                setTimeout(() => {
                    setRunJoyride(true);

                    setTimeout(() => {
                        const dialog = document.querySelector('.react-joyride__tooltip');

                        if (dialog) {
                            dialog.focus();
                            //document.addEventListener('keydown', arrowDownHandler);
                        }
                    }, 25);
                }, 25);
            }, 125);
        }

        /*  return () => {
            const dialog = document.querySelector('.react-joyride__tooltip');

            if (dialog) {
                document.removeEventListener('keydown', arrowDownHandler);
            }
        }; */
    }, [userToursSeen?.length, path, userToursSeen, runJoyride]);

    return (
        <Joyride
            callback={data => {
                const { status, lifecycle, action /*, step */ } = data;

                if (action === ACTIONS.UPDATE && lifecycle === LIFECYCLE.TOOLTIP) {
                    console.log('waiting for scroll end');
                    waitForScrollEnd().then(() => {
                        window.dispatchEvent(new Event('resize'));
                    });
                }

                const nextButton = document.querySelector(
                    '.react-joyride__tooltip button[data-action="primary"]'
                );

                // show the steps in the aria label for the next button
                if (nextButton && nextButton.querySelector('span')?.textContent) {
                    nextButton.ariaLabel = nextButton.querySelector('span').textContent;
                }

                /* if (status === STATUS.RUNNING && lifecycle === 'tooltip') {
                    const target = document.querySelector(step.target);
                    console.log(
                        'target',
                        target,
                        'isElementInViewport',
                        isElementInViewport(target)
                    );
                    if (target && !isElementInViewport(target)) {
                        scrollToElement(target);
                    }
                } */

                // check if the tour has been completed by either skipping or finishing it
                if (
                    (status === STATUS.FINISHED || status === STATUS.SKIPPED) &&
                    lifecycle === LIFECYCLE.COMPLETE
                ) {
                    document.body.style.overflow = 'auto';
                    setRunJoyride(false);
                    setUserToursSeen(prev => [...prev, path]);
                }
            }}
            continuous
            scrollOffset={0}
            disableOverlayClose
            disableScrollParentFix
            hideCloseButton
            locale={{
                skip: <strong>{t('skip')}</strong>,
                next: t('dashboardGuideNextButton'),
                back: t('dashboardGuideBackButton'),
                last: (
                    <span aria-label={t('dashboardGuideLastButtonAria')}>
                        {t('dashboardGuideLastButton')}
                    </span>
                ),
                close: t('dashboardGuideCloseButton'),
                open: t('dashboardGuideOpenButton')
            }}
            run={runJoyride}
            showSkipButton
            steps={steps}
            showProgress={true}
            styles={{
                options: {
                    zIndex: 9000,
                    primaryColor: '#000'
                }
            }}
            {...joyrideProps}
        />
    );
}

UserTour.propTypes = {
    isIntroTour: PropTypes.bool,
    joyrideProps: PropTypes.object,
    steps: PropTypes.array.isRequired
};

export default UserTour;

import { useFetchJiraComponents } from '@http/queries';
import { Autocomplete, TextField } from '@mui/material';

export function ProjectComponents({ components, allowedValues, handleChange, projectKey }) {
    const handleComponentsChange = (e, newValue) => {
        // handleChange('issuetype', e.target.value);
        console.log('handleComponentsChange', newValue);
        handleChange('components', newValue);
    };
    const { data: projectComponents } = useFetchJiraComponents(
        projectKey,
        !allowedValues && !!projectKey
    );

    const options = allowedValues ?? projectComponents ?? [];

    return (
        <Autocomplete
            multiple
            fullWidth
            value={components}
            id='jira-project-compoments-select'
            options={options}
            getOptionLabel={option => option?.name}
            onChange={handleComponentsChange}
            renderInput={params => <TextField {...params} variant='standard' />}
        />
    );
}

import {
    accessibilityDataAtom,
    currentAuditEntryAtom,
    leftDrawerOpenAtom,
    reportDemoModeAtom,
    userAtom
} from '@/global-store';
import LoadingSpinner from '@components/LoadingSpinner';
// import Breadcrumbs from './Breadcrumbs';
import useWindowSize from '@hooks/useWindowSize';
import { Alert, AppBar, Box, Toolbar } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';
import { useEffect, useRef } from 'react';
import { Outlet, useLocation, useNavigation } from 'react-router-dom';
import Footer from './Footer';
//import UserTour from '@components/UserTour.jsx';
import DemoModeBanner from '@/components/DemoModeBanner';
import FeatureOfferBanner from '@/components/FeatureOfferBanner';
import PageTitle from '@/components/PageTitle';
import { sidebarWidth } from '@/helpers/constants.js';
import { useFetchAccessibilityData } from '@/http/queries';
import Grid from '@mui/material/Grid2';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import Header from './Header/Header.jsx';
import Sidebar from './Sidebar/Sidebar.jsx';

const Main = styled('main', { shouldForwardProp: prop => prop !== 'open' })(({ theme }) => ({
    backgroundColor: '#f0f0f0',
    width: '100%',
    maxWidth: `calc(100% - (${sidebarWidth}px + 38px))`,
    minHeight: 'calc(100vh - 68px)',
    flexGrow: 1,
    padding: '38px',
    marginRight: '38px',
    marginTop: '65px',
    borderRadius: theme.borders.borderRadius.xl,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,

    [theme.breakpoints.down('xxl')]: {
        marginRight: 0,
        padding: '34px',
        maxWidth: `calc(100% - (${sidebarWidth}px))`
    },

    [theme.breakpoints.down('xl')]: {
        padding: '30px',
        marginRight: 0,
        maxWidth: '100%'
    },

    [theme.breakpoints.down('sm')]: {
        padding: '0px'
    },

    variants: [
        {
            props: ({ open }) => !open,

            style: {
                transition: theme.transitions.create('margin', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen
                })
            }
        },
        {
            props: ({ open }) => open,

            style: {
                transition: theme.transitions.create('margin', {
                    easing: theme.transitions.easing.easeOut,
                    duration: theme.transitions.duration.enteringScreen
                })
            }
        }
    ]
}));

function LastAuditTooOldBanner() {
    const [accessibilityData] = useAtom(accessibilityDataAtom);
    const [currentAuditEntry] = useAtom(currentAuditEntryAtom);
    const { t } = useTranslation();

    if (!accessibilityData?.history || !currentAuditEntry?.auditInterval) return null;

    const historyKeys = Object.keys(accessibilityData.history);
    const lastAudit = historyKeys[historyKeys?.length - 1];

    if (!lastAudit) return null;

    const lastAuditMs = new Date(lastAudit).getTime();
    const now = new Date().getTime();
    const auditIntervalMs = 1000 * 60 * 60 * 24 * (currentAuditEntry.auditInterval + 3); // add two day buffer

    if (lastAuditMs + auditIntervalMs > now) return null;

    return (
        <Alert
            severity='error'
            sx={theme => ({
                'border': '4px solid black',
                'color': theme.palette.black.main,
                'alignItems': 'center',
                'display': 'flex',
                'flexWrap': 'nowrap',
                'width': '100%',
                'my': 3,
                'p': 2,
                '& .MuiAlert-message': {
                    flexShrink: 1,
                    display: 'flex',
                    alignItems: 'center',
                    fontWeight: 700
                }
            })}
        >
            {t('lastAuditTooOld')}
        </Alert>
    );
}

function ReportLicenseExpiredBanner() {
    const { t } = useTranslation();

    const [user] = useAtom(userAtom);

    return (
        <>
            <Alert
                severity='error'
                sx={theme => ({
                    'border': '4px solid black',
                    'color': theme.palette.black.main,
                    'alignItems': 'center',
                    'display': 'flex',
                    'flexWrap': 'nowrap',
                    'width': '100%',
                    'my': 3,
                    'p': 2,
                    '& .MuiAlert-message': {
                        flexShrink: 1,
                        display: 'flex',
                        alignItems: 'center',
                        fontWeight: 700
                    }
                })}
            >
                {t('reportLicenseExpired')}
            </Alert>

            {user.role === 'customer' && <FeatureOfferBanner product='Eye-Able® Report' />}
        </>
    );
}

function showPageTitle(path) {
    const hiddenPaths = [
        '/ally',
        '/contrast-check',
        '/admin/',
        '/assist/',
        '/audit',
        '/demo',
        '/privacy-policy'
    ];

    for (const hiddenPath of hiddenPaths) {
        if (path.startsWith(hiddenPath)) return false;
    }

    return true;
}

// ==============================|| MAIN LAYOUT ||============================== //
function MainLayout() {
    const theme = useTheme();
    const location = useLocation();

    const [accessibilityData, setAccessibilityData] = useAtom(accessibilityDataAtom);

    const { t } = useTranslation();

    const [user] = useAtom(userAtom);

    const path = location.pathname.endsWith('/')
        ? location.pathname.slice(0, -1)
        : location.pathname;

    const isReportArea = path.startsWith('/report');

    // fetch the accessibility data for the current auditEntry and update the atom if the data changes
    const { isError, isLoading } = useFetchAccessibilityData({
        onDataChanged: data => setAccessibilityData(data)
    });

    const showDomainSelect =
        isReportArea &&
        !isLoading &&
        !path.startsWith('/report/accessibility-statement/generator') &&
        !path.startsWith('/report/manual-tests/manual-test');
    !path.startsWith('/report/manual-tests/guided-test');

    const showConformanceTargetSelect =
        isReportArea &&
        accessibilityData &&
        !isError &&
        !isLoading &&
        !path.startsWith('/report/accessibility-statement') &&
        !path.startsWith('/report/analysis') &&
        !path.startsWith('/report/manual-tests/manual-test');
    !path.startsWith('/report/manual-tests/guided-test');

    const [reportDemoMode] = useAtom(reportDemoModeAtom);
    const [currentAuditEntry] = useAtom(currentAuditEntryAtom);

    const reportLicenseExpired = currentAuditEntry?.endDate
        ? new Date(currentAuditEntry.endDate).setHours(23, 59, 59) < new Date()
        : false;

    /*const steps = [
    {
      content: t('dashboardGuideHomeWelcome'),
      title: t('dashboardGuideTitle'),
      placement: 'center',
      target: 'body',
      disableBeacon: true
    },
    {
      target: '#header-right-section',
      content: t('dashboardGuideHomeStep1'),
      disableBeacon: true
    },
    {
      target: '#collapse-assist',
      content: t('dashboardGuideHomeStep3'),
      disableBeacon: true
    },
    {
      target: '#route-audit',
      content: t('dashboardGuideHomeStep4'),
      disableBeacon: true
    },
    {
      target: '#list-subheader-report',
      content: t('dashboardGuideHomeStep5'),
      disableBeacon: true
    },
    {
      target: '#collapse-report-analysis',
      content: t('dashboardGuideHomeStep6'),
      disableBeacon: true
    },
    {
      target: '#list-subheader-tools',
      content: t('dashboardGuideHomeStep7'),
      disableBeacon: true
    },
    {
      target: '#support-popup',
      content: t('dashboardGuideHomeStep8'),
      disableBeacon: true
    }
  ];*/

    const windowSize = useWindowSize();

    const navigation = useNavigation();

    // Handle left drawer
    const [leftDrawerOpen, setLeftDrawerOpen] = useAtom(leftDrawerOpenAtom);

    const handleLeftDrawerToggle = () => {
        setLeftDrawerOpen(!leftDrawerOpen);
    };

    const appNavbar = useRef(null);

    useEffect(() => {
        function handleTransparentNavbar() {
            //const appNavbar = document.getElementById('AppNavbar');
            if (!appNavbar?.current) return;
            if (window.scrollY < 5) {
                appNavbar.current.style.background = 'none';
                appNavbar.current.style['backdrop-filter'] = 'none';
            } else {
                appNavbar.current.style.background = 'rgba(0,0,0, 0.85)';
                appNavbar.current.style['backdrop-filter'] = 'blur(5px)';
            }
        }

        /**
         The event listener that's calling the handleTransparentNavbar function when
         scrolling the window.
         */
        window.addEventListener('scroll', handleTransparentNavbar);

        // Call the handleTransparentNavbar function to set the state with the initial value.
        handleTransparentNavbar();

        return () => window.removeEventListener('scroll', handleTransparentNavbar);
    }, []);

    useEffect(() => {
        function sidenavEventCallback() {
            const xlAndUp = window.innerWidth >= theme.breakpoints.values.xl;
            setLeftDrawerOpen(xlAndUp);
        }
        sidenavEventCallback();
    }, [location.pathname, windowSize.width, setLeftDrawerOpen, theme.breakpoints.values.xl]);

    return (
        <Box sx={{ display: 'flex' }}>
            {/* header */}
            <AppBar
                elevation={0}
                enableColorOnDark
                id='AppNavbar'
                position='fixed'
                ref={appNavbar}
                sx={[
                    {
                        background: 'transparent',
                        color: theme.palette.primary.white,
                        p: 0
                    },
                    leftDrawerOpen
                        ? {
                              transition: theme.transitions.create('width')
                          }
                        : {
                              transition: 'none'
                          }
                ]}
            >
                <Toolbar
                    sx={{
                        minHeight: '64px',
                        background: 'transparent',
                        justifyContent: 'space-between',
                        pl: {
                            xs: 2,
                            sm: 2.25,
                            xl: 2,
                            xxl: 1.75
                        },
                        pr: {
                            xs: 2,
                            sm: 2.25,
                            xl: 2,
                            xxl: '32px !important'
                        }
                    }}
                >
                    <Header handleLeftDrawerToggle={handleLeftDrawerToggle} />
                </Toolbar>
            </AppBar>
            {/* drawer */}
            <Sidebar drawerOpen={leftDrawerOpen} drawerToggle={handleLeftDrawerToggle} />
            {/* main content */}
            <Main id='main-content' open={leftDrawerOpen}>
                <Grid
                    container
                    direction='column'
                    spacing={3}
                    sx={({ breakpoints }) => ({
                        margin: '0 auto',
                        maxWidth: breakpoints.values.xxl + 'px',
                        height: '100%'
                    })}
                >
                    {/* <Breadcrumbs /> */}

                    {path && showPageTitle(path) && (
                        <Grid size={12}>
                            <PageTitle
                                title={document?.title?.replace(' | Eye-Able® Dashboard', '')}
                                showDomainSelect={showDomainSelect}
                                showConformanceTarget={showConformanceTargetSelect}
                            />
                        </Grid>
                    )}

                    {navigation.state === 'loading' || (isReportArea && isLoading) ? (
                        <LoadingSpinner />
                    ) : isReportArea ? (
                        <>
                            {isError && path !== '/report/settings' ? (
                                <Grid
                                    container
                                    sx={{
                                        alignItems: 'center',
                                        gap: 5,
                                        justifyContent: 'center',
                                        p: 4,
                                        background: 'white',
                                        borderRadius: 3
                                    }}
                                >
                                    <Grid size={12}>
                                        <h2>{t('noReportAvailable')}</h2>
                                    </Grid>
                                </Grid>
                            ) : (
                                <>
                                    {reportDemoMode ? (
                                        <DemoModeBanner product='Report' />
                                    ) : reportLicenseExpired ? (
                                        <ReportLicenseExpiredBanner />
                                    ) : (
                                        <LastAuditTooOldBanner />
                                    )}

                                    {reportLicenseExpired &&
                                    user.role === 'customer' &&
                                    path !== '/report/overview' ? null : (
                                        <Outlet />
                                    )}

                                    {/*<UserTour steps={steps} isIntroTour />*/}
                                </>
                            )}
                        </>
                    ) : (
                        <Outlet />
                    )}

                    <Footer />
                </Grid>
            </Main>
        </Box>
    );
}

export default MainLayout;

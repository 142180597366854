import BlogCards from '@components/BlogCards.jsx';
import { useTranslation } from 'react-i18next';

const imageBaseUrl = 'https://assets.eye-able.com/dashboard-assets/showcase/';

export default function MainBlogArticles() {
    const { t, i18n } = useTranslation();

    function getImage(name) {
        if (i18n.resolvedLanguage === 'de') {
            return `${imageBaseUrl}/ea-${name}-de.png`;
        } else return `${imageBaseUrl}/ea-${name}-en.png`;
    }

    const articles = [
        {
            image: getImage('assist'),
            label: t('dashboardBlog1Label'),
            title: t('dashboardBlog1heading'),
            description: t('dashboardBlog1Text'),
            to: 'EAassist'
        },
        {
            image: getImage('audit'),
            label: t('dashboardBlog2Label'),
            title: t('dashboardBlog2heading'),
            description: t('dashboardBlog2Text'),
            to: 'EAaudit'
        },
        {
            image: getImage('extension'),
            label: t('dashboardBlog3Label'),
            title: t('dashboardBlog3heading'),
            description: t('dashboardBlog3Text'),
            to: 'EAassist'
        },
        {
            image: imageBaseUrl + 'ea-mission-en.png',
            label: t('dashboardBlog4Label'),
            title: t('dashboardBlog4heading'),
            description: t('dashboardBlog4Text'),
            to: 'EAmission'
        }
    ];

    return <BlogCards articles={articles} />;
}

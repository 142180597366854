import {
    activeNavItemAtom,
    assistDemoModeAtom,
    auditDemoModeAtom,
    easyDemoModeAtom,
    translateDemoModeAtom,
    demoModeAtom,
    leftDrawerOpenAtom,
    reportDemoModeAtom
} from '@/global-store.jsx';
import NavBadge from '@/layout/NavBadge.jsx';
import {
    Box,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Tooltip,
    Typography,
    useMediaQuery
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { IconPoint } from '@tabler/icons-react';
import { useAtom } from 'jotai';
import PropTypes from 'prop-types';
import { forwardRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

// ==============================|| SIDEBAR MENU LIST ITEMS ||============================== //

function NavItem({ item, level, collapsibleTitle = '' }) {
    const { t } = useTranslation();
    const theme = useTheme();
    const [, setLeftDrawerOpen] = useAtom(leftDrawerOpenAtom);
    const [activeNavItem, setActiveNavItem] = useAtom(activeNavItemAtom);
    const [reportDemoMode] = useAtom(reportDemoModeAtom);
    const [assistDemoMode] = useAtom(assistDemoModeAtom);
    const [auditDemoMode] = useAtom(auditDemoModeAtom);
    const [easyDemoMode] = useAtom(easyDemoModeAtom);
    const [translateDemoMode] = useAtom(translateDemoModeAtom);
    const [generalDemoMode] = useAtom(demoModeAtom);

    const location = useLocation();
    const { pathname } = location;

    const isLargeScreen = useMediaQuery(theme.breakpoints.up('xl'));

    const selected = activeNavItem === item.path;

    const Icon = item?.handle?.icon;
    const itemIcon = item?.handle?.icon ? (
        <Icon size='1.35rem' strokeWidth={1.5} />
    ) : (
        <IconPoint
            fontSize='inherit'
            sx={{
                width: 6,
                height: 6
            }}
        />
    );

    let itemTarget = '_self';
    if (item.target) {
        itemTarget = '_blank';
    }

    let listItemProps = {
        component: forwardRef(function ListItemLink(props, ref) {
            return <Link ref={ref} {...props} target={itemTarget} to={item.path} />;
        })
    };

    if (item?.handle?.external) {
        listItemProps = { component: 'a', href: item.path, target: itemTarget };
    }

    const itemHandler = path => {
        setActiveNavItem(path);
        //console.log('setActiveNavItem', path);
        if (!isLargeScreen) {
            setLeftDrawerOpen(false);
        }
    };

    // active menu item on page load
    useEffect(() => {
        if (item.path === '/') {
            if (pathname === '/') setActiveNavItem('/');
        } else if (pathname?.includes(item.path)) {
            //console.log('pathname includes', item.path, 'pathname:', pathname);
            setActiveNavItem(item.path);
        }
    }, [pathname, item.path, setActiveNavItem]);

    let showBadge = false;
    if (item.handle?.badge) {
        if (item.handle.badge.showWhen === 'always') showBadge = true;
        else if (!generalDemoMode) {
            // dont show these badges in demo mode
            if (item.handle.badge.showWhen === 'reportDemoMode' && reportDemoMode) showBadge = true;
            else if (item.handle.badge.showWhen === 'auditDemoMode' && auditDemoMode)
                showBadge = true;
            else if (item.handle.badge.showWhen === 'assistDemoMode' && assistDemoMode)
                showBadge = true;
            else if (item.handle.badge.showWhen === 'translateDemoMode' && translateDemoMode) {
                showBadge = true;
            } else if (item.handle.badge.showWhen === 'easyDemoMode' && easyDemoMode)
                showBadge = true;
        }
    }

    const title = item.handle.i18nNameKey
        ? t(item.handle.i18nNameKey)
        : item.handle.name || 'NO ROUTE NAME PROVIDED';

    return (
        <Tooltip
            title={item.handle.i18nTooltipKey ? t(item.handle.i18nTooltipKey) : null}
            placement='right'
            describeChild
            slotProps={{
                popper: {
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 12]
                            }
                        }
                    ]
                }
            }}
        >
            <ListItemButton
                {...listItemProps}
                aria-label={title + (collapsibleTitle ? ` - ${collapsibleTitle}` : '')}
                role='link'
                aria-current={selected ? 'page' : undefined}
                disabled={item.disabled}
                onClick={() => itemHandler(item.path)}
                selected={selected}
                sx={[
                    theme => ({
                        'borderRadius': theme.borders.borderRadius.xl,
                        'm': 0,
                        'mb': '4px',
                        'display': 'flex',
                        'alignItems': 'flex-start',
                        'backgroundColor': 'inherit',
                        'padding': '9px 14px !important',
                        'color': '#fff !important',
                        'lineHeight': '1 !important',
                        'transition': 'none',

                        '& .MuiListItemIcon-root, & svg': {
                            color: '#fff !important'
                        },

                        '& .custom-svg-icon': {
                            width: '1.35rem',
                            height: '1.35rem'
                        },

                        '&.Mui-selected': {
                            'color': `#000 !important`,
                            'backgroundColor': theme.palette.secondary.main + '!important',

                            '& .MuiListItemIcon-root, & svg': {
                                color: '#000 !important'
                            }
                        },

                        '&:hover, &:focus': {
                            'outline': '2px solid white',
                            'outlineOffset': '-1px',
                            'backgroundColor': '#fff !important',
                            'color': '#000 !important',

                            '& .MuiListItemIcon-root, & svg': {
                                color: '#000 !important'
                            }
                        }
                    }),
                    level > 1
                        ? {
                              ml: `${19 * level}px !important`
                          }
                        : {
                              ml: 0
                          }
                ]}
            >
                <ListItemIcon sx={{ my: 'auto', minWidth: '36px' }}>{itemIcon}</ListItemIcon>

                <ListItemText
                    primary={
                        <Typography
                            color='inherit'
                            component='div'
                            variant='body1'
                            sx={{
                                alignItems: 'center',
                                display: 'flex',
                                flexWrap: 'wrap',
                                fontWeight: 400,
                                letterSpacing: '0.25px',
                                gap: 1.5
                            }}
                        >
                            <Box
                                sx={{
                                    flexGrow: 0
                                }}
                            >
                                {title}
                            </Box>

                            {showBadge ? (
                                <NavBadge
                                    sx={{
                                        fontSize: '12px',
                                        mr: 1.25
                                    }}
                                    text={item.handle.badge.text}
                                />
                            ) : null}
                        </Typography>
                    }
                    secondary={
                        item.handle?.i18nCaptionKey ? (
                            <Typography component='div' variant='subMenuCaption'>
                                {t(item.handle.i18nCaptionKey)}
                            </Typography>
                        ) : null
                    }
                    sx={{ m: 0 }}
                />
            </ListItemButton>
        </Tooltip>
    );
}

NavItem.propTypes = {
    item: PropTypes.object,
    level: PropTypes.number
};

export default NavItem;

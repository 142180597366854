import DynamicDialog from '@components/DynamicDialog';
import { Box } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
// import useClientSize from "@hooks/useClientSize";
// import { useAtom } from 'jotai';
// import { useTranslation } from 'react-i18next';

function ImageWithPreview({ imgSrc, alt, useNaturalImageSize = false }) {
    const [image, setImage] = useState();
    const [imageLoadError, setImageLoadError] = useState(false);
    // const [imageWrapper, setImageWrapper] = useState();
    // const imageWrapperClientSize = useClientSize(imageWrapper);
    //const { t } = useTranslation();

    const maxPreviewWidth = 290;
    const maxPreviewHeight = 164;

    useEffect(() => {
        if (!imgSrc) return;
        // load the image after the component is mounted to display a placeholder
        const image = new Image();
        image.onerror = () => {
            setImageLoadError(true);
            setImage(null);
        };
        image.onload = () => {
            setImageLoadError(false);
            setImage(image);
        };
        image.src = imgSrc;
    }, [imgSrc]);

    const naturalSize = useMemo(() => {
        if (!image) return { width: 0, height: 0 };
        return {
            width: image.naturalWidth,
            height: image.naturalHeight
        };
    }, [image]);

    const wrapperPaddingBottom = useMemo(() => {
        return (naturalSize.height / naturalSize.width) * 100 + '%';
    }, [naturalSize.width, naturalSize.height]);

    /*   const aspectRatio = useMemo(() => {
      return naturalSize.height / naturalSize.width;
    }, [naturalSize]); */

    const previewWidth = useMemo(() => {
        return Math.min(naturalSize.width, maxPreviewWidth);
    }, [naturalSize.width]);

    /*   const previewHeight = useMemo(() => {
    const height = previewWidth * aspectRatio;
    return Math.min(height, maxPreviewHeight);
  }, [previewWidth, aspectRatio]); */

    const wrapperStyle = {
        position: 'relative',
        overflow: 'hidden',
        cursor: 'pointer',
        width: `${previewWidth}px`,
        height: `${maxPreviewHeight}px`
    };

    const previewStyle = {
        position: 'absolute',
        objectFit: 'contain',
        top: 0,
        left: 0,
        width: '100%',
        height: 'auto'
    };

    if (!image || imageLoadError) {
        return (
            <img
                alt='Placeholder image'
                src='https://assets.eye-able.com/dashboard-assets/icons/placeholder-image.png'
                style={{ objectFit: 'contain', padding: '6px', height: '70px' }}
            />
        );
    }

    return (
        <DynamicDialog
            activatorProps={{
                'aria-label': alt,
                'sx': { p: 0, overflow: 'hidden', height: '100%', minHeight: '168px' }
            }}
            activatorText={
                <Box aria-hidden sx={wrapperStyle}>
                    <img alt={alt} loading='eager' src={image.src} style={previewStyle} />
                </Box>
            }
            alwaysFullscreen
            aria-hidden
            hideActions
            title={alt}
        >
            {useNaturalImageSize ? (
                <Box
                    sx={{
                        textAlign: 'center'
                    }}
                >
                    <img
                        alt={alt}
                        loading='eager'
                        src={image.src}
                        style={{
                            zIndex: 1
                        }}
                    />
                </Box>
            ) : (
                <Box
                    sx={{
                        position: 'relative',
                        width: '100%',
                        height: 0,
                        outline: '4px solid black',
                        paddingBottom: wrapperPaddingBottom
                    }}
                >
                    <img
                        alt={alt}
                        loading='eager'
                        src={image.src}
                        style={{
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                            objectFit: 'contain',
                            top: 0,
                            left: 0,
                            zIndex: 1
                        }}
                    />
                </Box>
            )}
        </DynamicDialog>
    );
}

export default ImageWithPreview;

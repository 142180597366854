import HeaderChip from '@components/HeaderChip.jsx';
import { Menu, MenuItem } from '@mui/material';
import { t } from 'i18next';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

function flag(code) {
    return 'https://assets.eye-able.com/dashboard-assets/flags/' + code + '.svg';
}

const languages = [
    {
        code: 'de',
        name: 'Deutsch'
    },
    {
        code: 'en',
        name: 'English'
    },
    {
        code: 'es',
        name: 'Español'
    },
    {
        code: 'fr',
        name: 'Français'
    },
    {
        code: 'it',
        name: 'Italiano'
    },
    {
        code: 'nl',
        name: 'Nederlands'
    },
    {
        code: 'pl',
        name: 'Polski'
    },
    {
        code: 'pt',
        name: 'Português'
    }
];

//language setting component
function LanguageSelect() {
    const { i18n } = useTranslation();

    let currentLang = i18n.resolvedLanguage;

    const [anchorEl, setAnchorEl] = useState(null);

    const selectedIndex = languages.findIndex(x => x.code === currentLang);

    const open = Boolean(anchorEl);
    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuItemClick = async (event, index) => {
        setAnchorEl(null);
        console.log('change language to', languages[index].code);
        await i18n.changeLanguage(languages[index].code);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <HeaderChip
                onClick={handleClick}
                aria-expanded={open ? 'true' : undefined}
                id='lang-button'
                aria-haspopup='listbox'
                aria-controls='lang-menu'
                tooltip={t('changeLanguage')}
                imgSrc={flag(currentLang)}
            />

            <Menu
                id='lang-menu'
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'lang-button',
                    'role': 'listbox'
                }}
            >
                {languages.map((option, index) => (
                    <MenuItem
                        key={option.code}
                        selected={index === selectedIndex}
                        aria-current={index === selectedIndex ? 'true' : undefined}
                        onClick={event => handleMenuItemClick(event, index)}
                    >
                        <img
                            src={flag(option.code)}
                            style={{
                                width: '36px',
                                height: '27px',
                                marginRight: '12px',
                                border: '1.5px solid #000'
                            }}
                            alt=''
                            aria-hidden='true'
                        />
                        <b>{option.name}</b>
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
}

export default LanguageSelect;

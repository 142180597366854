import BlogCard from '@components/BlogCard';
import Grid from '@mui/material/Grid2';

function BlogCards({ articles }) {
    return (
        <Grid
            container
            spacing={2.5}
            sx={{
                alignItems: 'stretch',
                p: 0
            }}
        >
            {articles.map(article => (
                <Grid
                    key={article.title + article.to}
                    size={{
                        sm: 6,
                        xl: 3,
                        xs: 12
                    }}
                >
                    <BlogCard
                        description={article.description}
                        image={article.image}
                        title={article.title}
                        to={article.to}
                    />
                </Grid>
            ))}
        </Grid>
    );
}

export default BlogCards;

import ErrorPage from '@/ErrorPage';
import { AllyIcon, PlainLanguageIcon, TranscriptionIcon } from '@/components/customIcons';
import {
    IconAccessible,
    IconAdjustmentsHorizontal,
    IconBrowserCheck,
    IconBuildingLighthouse,
    IconChartLine,
    IconClipboardPlus,
    IconEye,
    IconEyeCheck,
    IconFileCheck,
    IconFileDescription,
    IconFileInfo,
    IconFilePencil,
    IconFileTypePdf,
    IconFileUpload,
    IconFlask,
    IconGitCompare,
    IconHome2,
    IconLanguage,
    IconListCheck,
    IconListDetails,
    IconLockSquare,
    IconPalette,
    IconRadar,
    IconReport,
    IconReportAnalytics,
    IconSettings,
    IconTargetArrow,
    IconTextResize,
    IconTextSpellcheck,
    IconTools,
    IconTypography,
    IconUnlink,
    IconUserShield,
    IconUsersGroup,
    IconZoomExclamation
} from '@tabler/icons-react';
import { Outlet } from 'react-router-dom';
import BaseRoute from './BaseRoute';
import { default as WildcardRoute } from './WildcardRoute';
import Home from './pages/home/Home';

export default [
    {
        handle: {
            allowed: 'all'
        },
        path: '/',
        element: <BaseRoute />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: '/',
                index: true,
                handle: {
                    i18nNameKey: 'homepage',
                    icon: IconHome2,
                    allowed: 'user',
                    type: 'item'
                },
                element: <Home />
            },
            {
                path: 'privacy-policy',
                handle: {
                    type: 'hidden',
                    i18nNameKey: 'privacyPolicy',
                    allowed: 'all'
                },
                lazy: () => import('@pages/PrivacyPolicy.jsx')
            },
            {
                path: 'demo',
                handle: {
                    type: 'hidden',
                    name: 'Demo',
                    allowed: 'all'
                },
                lazy: () => import('@pages/Demo.jsx')
            },
            {
                path: 'authentication/login',
                lazy: () => import('./pages/authentication/Login.jsx'),
                handle: {
                    type: 'hidden',
                    i18nNameKey: 'signIn',
                    allowed: 'no-user'
                }
            },
            {
                path: 'authentication/change-password',
                lazy: () => import('./pages/authentication/ChangePassword.jsx'),
                handle: {
                    type: 'hidden',
                    i18nNameKey: 'changePassword',
                    allowed: 'all'
                }
            },
            {
                path: 'authentication/loginAsCustomer',
                lazy: () => import('./pages/authentication/LoginAsCustomer.jsx'),
                handle: {
                    type: 'hidden',
                    name: 'Login As Customer',
                    allowed: 'all'
                }
            },
            {
                path: '/organisation',
                lazy: () => import('./pages/organisation/Organisation.jsx'),
                handle: {
                    type: 'item',
                    i18nNameKey: 'myOrg',
                    icon: IconUsersGroup,
                    allowed: 'user',
                    i18nTooltipKey: 'myOrgTooltip'
                }
            },
            {
                path: '/assist',
                lazy: () => import('@pages/assist/AssistBaseRoute.jsx'),
                handle: {
                    name: 'Eye-Able® Assist',
                    icon: IconAccessible,
                    allowed: 'user',
                    type: 'collapse',
                    i18nTooltipKey: 'EaAssistTooltip',
                    showDividerBeforeThis: true,
                    i18nDividerKey: 'assistSoftware',
                    badge: {
                        text: 'demo',
                        showWhen: 'assistDemoMode'
                    }
                },
                children: [
                    {
                        index: true,
                        path: '/assist/overview',
                        lazy: () => import('@pages/assist/Assist.jsx'),
                        handle: {
                            i18nNameKey: 'overview',
                            allowed: 'user',
                            type: 'item',
                            icon: IconEye
                        }
                    },
                    {
                        path: '/assist/customize',
                        lazy: () => import('@pages/assist/Customize/Customize.jsx'),
                        handle: {
                            i18nNameKey: 'customize',
                            allowed: 'user',
                            type: 'item',
                            icon: IconAdjustmentsHorizontal
                        }
                    },
                    {
                        path: '/assist/installation',
                        lazy: () => import('@pages/assist/Installation.jsx'),
                        handle: {
                            i18nNameKey: 'installation',
                            allowed: 'user',
                            type: 'item',
                            icon: IconTools
                        }
                    },
                    {
                        path: '/assist/data-privacy',
                        lazy: () => import('@pages/assist/DataPrivacy.jsx'),
                        handle: {
                            i18nNameKey: 'dataPrivacy',
                            allowed: 'user',
                            type: 'item',
                            icon: IconLockSquare
                        }
                    }
                ]
            },
            {
                element: <Outlet />,
                path: '/plain-language',
                handle: {
                    i18nNameKey: 'plainLanguage',
                    icon: PlainLanguageIcon,
                    allowed: 'user',
                    type: 'collapse'
                },
                children: [
                    {
                        index: true,
                        path: '/plain-language/simplify',
                        lazy: () => import('./pages/plainLanguage/PlainLanguageText.jsx'),
                        handle: {
                            i18nNameKey: 'simplifyText',
                            allowed: 'user',
                            type: 'item',
                            icon: IconTypography
                        }
                    },
                    {
                        path: '/plain-language/document',
                        lazy: () => import('./pages/plainLanguage/PlainLanguageDocument.jsx'),
                        handle: {
                            i18nNameKey: 'simplifyDocument',
                            allowed: 'user',
                            type: 'item',
                            icon: IconFileUpload
                        }
                    },
                    {
                        path: '/plain-language/websites',
                        element: <Outlet />,
                        handle: {
                            i18nNameKey: 'websites',
                            allowed: 'user',
                            icon: IconListDetails,
                            badge: {
                                text: 'demo',
                                showWhen: 'easyDemoMode'
                            }
                        },
                        children: [
                            {
                                index: true,
                                path: '/plain-language/websites',
                                lazy: () => import('./pages/plainLanguage/Overview.jsx'),
                                handle: {
                                    i18nNameKey: 'websites',
                                    icon: IconListDetails,
                                    allowed: 'user'
                                }
                            },
                            {
                                index: true,
                                path: '/plain-language/websites/languages',
                                lazy: () => import('./pages/plainLanguage/EditorLanguages.jsx'),
                                handle: {
                                    i18nNameKey: 'websiteLanguages',
                                    icon: IconTextResize,
                                    allowed: 'user'
                                }
                            },
                            {
                                path: '/plain-language/websites/translation/:domainId/:translPair',
                                lazy: () => import('./pages/plainLanguage/EditorTranslations.jsx'),
                                handle: {
                                    i18nNameKey: 'manageTranslations',
                                    allowed: 'user'
                                }
                            }
                        ]
                    },
                    {
                        path: '/plain-language/installation',
                        lazy: () => import('./pages/plainLanguage/Installation.jsx'),
                        handle: {
                            i18nNameKey: 'installation',
                            allowed: 'user',
                            type: 'item',
                            icon: IconTools,
                            badge: {
                                text: 'demo',
                                showWhen: 'easyDemoMode'
                            }
                        }
                    },
                    {
                        path: '/plain-language/dataprivacy',
                        lazy: () => import('./pages/plainLanguage/DataPrivacy.jsx'),
                        handle: {
                            i18nNameKey: 'dataPrivacy',
                            allowed: 'user',
                            type: 'item',
                            icon: IconLockSquare
                        }
                    }
                ]
            },
            {
                element: <Outlet />,
                path: '/translate',
                handle: {
                    i18nNameKey: 'translate',
                    name: 'Eye-Able® Translate',

                    icon: IconLanguage,
                    allowed: 'user',
                    type: 'collapse',
                    badge: {
                        text: 'demo',
                        showWhen: 'translateDemoMode'
                    }
                },
                children: [
                    {
                        path: '/translate/websites',
                        element: <Outlet />,
                        handle: {
                            i18nNameKey: 'websites',
                            allowed: 'user',
                            type: 'item',
                            icon: IconListDetails
                        },
                        children: [
                            {
                                index: true,
                                path: '/translate/websites',
                                lazy: () => import('./pages/Translate/Overview.jsx'),
                                handle: {
                                    i18nNameKey: 'websites',
                                    icon: IconListDetails,
                                    allowed: 'user'
                                }
                            },
                            {
                                index: true,
                                path: '/translate/websites/languages',
                                lazy: () => import('./pages/Translate/EditorLanguages.jsx'),
                                handle: {
                                    i18nNameKey: 'websiteLanguages',
                                    icon: IconTextResize,
                                    allowed: 'user'
                                }
                            },
                            {
                                path: '/translate/websites/translation/:domainId/:translPair',
                                lazy: () => import('./pages/Translate/EditorTranslations.jsx'),
                                handle: {
                                    i18nNameKey: 'manageTranslations',
                                    allowed: 'user'
                                }
                            }
                        ]
                    },
                    {
                        path: '/translate/installation',
                        lazy: () => import('./pages/Translate/Installation.jsx'),
                        handle: {
                            i18nNameKey: 'installation',
                            allowed: 'user',
                            type: 'item',
                            icon: IconTools
                        }
                    },
                    {
                        path: '/translate/dataprivacy',
                        lazy: () => import('./pages/Translate/DataPrivacy.jsx'),
                        handle: {
                            i18nNameKey: 'dataPrivacy',
                            allowed: 'user',
                            type: 'item',
                            icon: IconLockSquare
                        }
                    }
                ]
            },
            // --- Audit AREA ---
            {
                handle: {
                    name: 'Eye-Able® Audit',
                    icon: IconRadar,
                    allowed: 'user',
                    type: 'item',
                    badge: {
                        text: 'demo',
                        showWhen: 'auditDemoMode'
                    },
                    i18nTooltipKey: 'EaAuditTooltip',
                    showDividerBeforeThis: true,
                    i18nDividerKey: 'auditSoftware'
                },
                path: '/audit',
                lazy: () => import('@pages/Audit.jsx')
            },
            {
                path: '/report',
                element: <Outlet />,
                handle: {
                    name: 'Eye-Able® Report',
                    icon: IconReport,
                    allowed: 'user',
                    type: 'collapse',
                    defaultExpanded: true,
                    i18nTooltipKey: 'EaReportTooltip',
                    badge: {
                        text: 'demo',
                        showWhen: 'reportDemoMode'
                    }
                },
                children: [
                    {
                        index: true,
                        path: '/report/overview',
                        lazy: () =>
                            import(
                                './pages/report/AccessibilityOverview/AccessibilityOverview.jsx'
                            ),
                        handle: {
                            i18nNameKey: 'overview',
                            icon: IconEyeCheck,
                            allowed: 'user',
                            type: 'item'
                        }
                    },
                    {
                        path: '/report/issues',
                        element: <Outlet />,
                        handle: {
                            i18nNameKey: 'issues',
                            icon: IconZoomExclamation,
                            allowed: 'user',
                            type: 'item'
                        },
                        children: [
                            {
                                index: true,
                                path: '/report/issues',
                                lazy: () => import('./pages/report/Issues/Issues.jsx'),
                                handle: {
                                    i18nNameKey: 'issues',
                                    icon: IconListCheck,
                                    allowed: 'user'
                                }
                            },
                            {
                                path: 'issue/:ruleId',
                                lazy: () => import('./pages/report/IssueDetail.jsx'),
                                handle: {
                                    i18nNameKey: 'issue',
                                    allowed: 'user'
                                }
                            }
                        ]
                    },
                    {
                        path: '/report/pages',
                        element: <Outlet />,
                        handle: {
                            i18nNameKey: 'mySites',
                            icon: IconBrowserCheck,
                            allowed: 'user',
                            type: 'item'
                        },
                        children: [
                            {
                                path: '/report/pages',
                                index: 'true',
                                lazy: () => import('./pages/report/Pages.jsx'),
                                handle: {
                                    i18nNameKey: 'mySites',
                                    allowed: 'user'
                                }
                            },
                            {
                                path: '/report/pages/page/:pageIndex',
                                lazy: () => import('./pages/report/PageDetail.jsx'),
                                handle: {
                                    i18nNameKey: 'pageDetails',
                                    allowed: 'user'
                                }
                            }
                        ]
                    },
                    {
                        path: '/report/settings',
                        lazy: () => import('./pages/report/ReportSettings/ReportSettings.jsx'),
                        handle: {
                            i18nNameKey: 'settings',
                            icon: IconSettings,
                            allowed: 'user',
                            type: 'item'
                        }
                    },
                    {
                        path: '*',
                        element: <WildcardRoute redirectTo='/report/overview' />,
                        handle: {
                            type: 'hidden'
                        }
                    }
                ]
            },
            {
                path: '/report/my-goals',
                lazy: () => import('./pages/report/MyGoals/MyGoals.jsx'),
                handle: {
                    i18nNameKey: 'myGoals',
                    icon: IconTargetArrow,
                    allowed: 'user',
                    type: 'item'
                }
            },
            {
                element: <Outlet />,
                path: '/report/pdfs',
                handle: {
                    name: 'PDFs',
                    icon: IconFileTypePdf,
                    allowed: 'user',
                    type: 'collapse'
                },
                children: [
                    {
                        index: true,
                        path: '/report/pdfs/',
                        lazy: () => import('./pages/report/Pdfs.jsx'),
                        handle: {
                            i18nNameKey: 'foundPdfs',
                            allowed: 'user',
                            type: 'item',
                            icon: IconFileCheck
                        }
                    },
                    {
                        path: '/report/pdfs/found/pdf/:title',
                        lazy: () => import('./pages/report/PdfDetail/PdfDetail.jsx'),
                        handle: {
                            name: 'PDF Details',
                            allowed: 'user',
                            type: 'hidden'
                        }
                    },
                    {
                        path: '/report/pdfs/upload',
                        element: <Outlet />,
                        handle: {
                            i18nNameKey: 'userPdfs',
                            icon: IconFileUpload,
                            allowed: 'user',
                            type: 'item'
                        },
                        children: [
                            {
                                path: '/report/pdfs/upload',
                                index: true,
                                lazy: () => import('./pages/report/UserPdfs.jsx'),
                                handle: {
                                    i18nNameKey: 'userPdfs',
                                    icon: IconFileUpload,
                                    allowed: 'user'
                                }
                            },
                            {
                                path: '/report/pdfs/upload/pdf/:title',
                                lazy: () => import('./pages/report/PdfDetail/PdfDetail.jsx'),
                                handle: {
                                    name: 'PDF Details',
                                    allowed: 'user'
                                }
                            }
                        ]
                    },
                    {
                        index: true,
                        path: '/report/pdfs/pdfsIssues',
                        lazy: () => import('./pages/report/pdfsIssues.jsx'),
                        handle: {
                            i18nNameKey: 'issues',
                            allowed: 'user',
                            type: 'item',
                            icon: IconZoomExclamation
                        }
                    }
                ]
            },
            {
                element: <Outlet />,
                path: '/report/analysis',
                handle: {
                    name: 'Content & Web Analysis',
                    icon: IconChartLine,
                    allowed: 'user',
                    type: 'collapse'
                },
                children: [
                    {
                        path: '/report/analysis/broken-links',
                        lazy: () => import('./pages/report/BrokenLinks.jsx'),
                        handle: {
                            name: 'Broken Links',
                            icon: IconUnlink,
                            allowed: 'user',
                            type: 'item'
                        }
                    },
                    {
                        path: '/report/analysis/lighthouse',
                        element: <Outlet />,
                        handle: {
                            name: 'Lighthouse',
                            icon: IconBuildingLighthouse,
                            allowed: 'user',
                            type: 'item',
                            //type: 'hidden',
                            badge: {
                                text: 'new',
                                showWhen: 'always'
                            }
                        },
                        children: [
                            {
                                index: true,
                                path: '/report/analysis/lighthouse',
                                lazy: () =>
                                    import('@pages/report/Lighthouse/LighthouseOverview.jsx'),
                                handle: {
                                    name: 'Lighthouse',
                                    icon: IconBuildingLighthouse,
                                    allowed: 'user'
                                }
                            },
                            {
                                path: '/report/analysis/lighthouse/page/:filename',
                                lazy: () =>
                                    import('@pages/report/Lighthouse/LighthouseDetailPage.jsx'),
                                handle: {
                                    name: 'Lighthouse Details For a Page',
                                    allowed: 'user'
                                }
                            }
                        ]
                    },
                    {
                        path: '/report/analysis/spellchecker',
                        element: <Outlet />,
                        handle: {
                            i18nNameKey: 'spellChecking',
                            icon: IconTextSpellcheck,
                            allowed: 'user',
                            type: 'item',
                            badge: {
                                text: 'new',
                                showWhen: 'always'
                            }
                        },
                        children: [
                            {
                                path: '/report/analysis/spellchecker',
                                index: 'true',
                                lazy: () => import('./pages/report/SpellChecker.jsx'),
                                handle: {
                                    i18nNameKey: 'spellChecking',
                                    allowed: 'user'
                                }
                            },
                            {
                                path: '/report/analysis/spellchecker/page/:pageIndex',
                                lazy: () => import('./pages/report/SpellCheckerPageDetail.jsx'),
                                handle: {
                                    i18nNameKey: 'pageDetails',
                                    allowed: 'user'
                                }
                            }
                        ]
                    },
                    {
                        path: '/report/analysis/fontOverview/',
                        lazy: () => import('./pages/report/fontOverview/fontOverview.jsx'),
                        handle: {
                            type: window.location.origin.includes('dev-3') ? 'item' : 'hidden',
                            name: 'fontOverview',
                            allowed: 'user',
                            i18nNameKey: 'fontOverview',
                            icon: IconFileInfo,
                            badge: {
                                text: 'new',
                                showWhen: 'always'
                            }
                        }
                    }
                ]
            },
            {
                element: <Outlet />,
                path: '/report/accessibility-statement',
                handle: {
                    i18nNameKey: 'accessibilityStatement',
                    icon: IconFileInfo,
                    allowed: 'user',
                    type: 'collapse'
                },
                children: [
                    {
                        index: true,
                        path: '/report/accessibility-statement/generator',
                        lazy: () => import('./pages/report/accessibility-statement/Generator.jsx'),
                        handle: {
                            i18nNameKey: 'generator',
                            allowed: 'user',
                            type: 'item',
                            badge: {
                                text: 'beta',
                                showWhen: 'always'
                            },
                            icon: IconFilePencil
                        }
                    },
                    {
                        path: '/report/accessibility-statement/generated-statements',
                        lazy: () =>
                            import(
                                './pages/report/accessibility-statement/GeneratedStatements.jsx'
                            ),
                        handle: {
                            icon: IconFileDescription,
                            i18nNameKey: 'accessibilityStatementGeneratedStatements',
                            allowed: 'user',
                            type: 'item',
                            badge: {
                                text: 'beta',
                                showWhen: 'always'
                            }
                        }
                    }
                ]
            },
            {
                element: <Outlet />,
                path: '/report/manual-tests',
                handle: {
                    i18nNameKey: 'manualTests',
                    icon: IconFlask,
                    allowed: window.location.origin.includes('dev-2') ? 'salesAdmin' : 'admin',
                    type: 'collapse',
                    badge: {
                        text: 'beta',
                        showWhen: 'always'
                    }
                },
                children: [
                    {
                        path: '/report/manual-tests/completed-tests',
                        lazy: () => import('./pages/report/ManualTests/GeneratedManualTests.jsx'),
                        handle: {
                            i18nNameKey: 'completedTests',
                            allowed: 'user',
                            type: 'item',
                            icon: IconFileDescription
                        }
                    },
                    /* {
                        index: true,
                        path: '/report/manual-tests/guided-test',
                        lazy: () => import('./pages/report/accessibility-statement/Generator.jsx'),
                        handle: {
                            i18nNameKey: 'guidedTest',
                            allowed: 'user',
                            type: 'item',
                            icon: IconFilePencil
                        }
                    }, */
                    {
                        path: '/report/manual-tests/manual-test',
                        lazy: () => import('./pages/report/ManualTests/ManualTest.jsx'),
                        handle: {
                            name: 'Manual Test [INTERN]',
                            allowed: 'salesAdmin',
                            type: 'item',
                            icon: IconClipboardPlus
                        }
                    },
                    {
                        path: '/report/manual-tests/guided-test',
                        lazy: () => import('./pages/report/ManualTests/GuidedTests/GuidedTest.jsx'),
                        handle: {
                            name: 'Guided Test',
                            allowed: 'user',
                            type: 'item',
                            icon: IconClipboardPlus
                        }
                    }
                ]
            },
            {
                path: '/report/compare',
                lazy: () => import('./pages/report/CompareReport/CompareReport.jsx'),
                handle: {
                    i18nNameKey: 'reportComparison',
                    icon: IconGitCompare,
                    allowed: 'user',
                    type: 'hidden'
                }
            },
            // --- TOOLS ---
            {
                path: '/ally',
                lazy: () => import('./pages/ally/Ally.jsx'),
                handle: {
                    type: 'item',
                    i18nNameKey: 'allyAssistant',
                    icon: AllyIcon,
                    allowed: 'user',
                    badge: {
                        text: 'new',
                        showWhen: 'always'
                    },
                    showDividerBeforeThis: true,
                    i18nDividerKey: 'tools'
                }
            },
            {
                path: '/transcription',
                element: <Outlet />,
                handle: {
                    i18nNameKey: 'transcription',
                    icon: TranscriptionIcon,
                    allowed: 'user',
                    type: 'item',
                    badge: {
                        text: 'beta',
                        showWhen: 'always'
                    }
                },
                children: [
                    {
                        path: '/transcription',
                        index: 'true',
                        lazy: () => import('@pages/Transcription/Transcription.jsx'),
                        handle: {
                            i18nNameKey: 'transcription',
                            allowed: 'user'
                        }
                    },
                    {
                        path: '/transcription/:mediaUrl',
                        lazy: () => import('@pages/Transcription/Transcription.jsx'),
                        handle: {
                            i18nNameKey: 'transcription',
                            allowed: 'user'
                        }
                    }
                ]
            },
            {
                path: '/contrast-check',
                lazy: () => import('./pages/ContrastCheck.jsx'),
                handle: {
                    i18nNameKey: 'contrastChecker',
                    icon: IconPalette,
                    allowed: 'user',
                    type: 'item'
                }
            },
            // --- (sales) ADMIN AREA ---
            {
                path: '/admin/panel',
                index: true,
                lazy: () => import('./pages/admin/AdminPanel.jsx'),
                handle: {
                    name: 'Users & Licenses',
                    icon: IconUserShield,
                    allowed: 'salesAdmin',
                    type: 'item',
                    showDividerBeforeThis: true,
                    i18nDividerKey: 'admin'
                }
            },
            {
                path: '/admin/crawler-status',
                index: true,
                lazy: () => import('./pages/admin/CrawlerStatus.jsx'),
                handle: {
                    name: 'Crawler Status',
                    icon: IconReportAnalytics,
                    allowed: 'admin',
                    type: 'item'
                }
            },
            {
                path: '*',
                handle: {
                    allowed: 'all',
                    type: 'hidden'
                },
                element: <WildcardRoute redirectTo='/' />
            }
        ]
    }
];

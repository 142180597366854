import { scrollBarStylesOnBlack } from '@/assets/theme/base/scrollbarStyles';
import { userAtom } from '@/global-store';
import routes from '@/routes';
import { Divider, List } from '@mui/material';
import { useAtom } from 'jotai';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import NavCollapse from './NavCollapse';
import NavItem from './NavItem';

// ==============================|| SIDEBAR MENU LIST ||============================== //

function MenuList() {
    const [user] = useAtom(userAtom);

    const { t } = useTranslation();

    const mainRouteChildren = routes[0].children;

    const items = useMemo(() => {
        if (!user) return [];

        const _items = [];

        for (let item of mainRouteChildren) {
            let allowed = item.handle?.allowed;

            let showItem = true;

            if (allowed) {
                if (!Array.isArray(allowed)) {
                    allowed = [allowed];
                }

                for (let allowedItem of allowed) {
                    switch (allowedItem) {
                        case 'no-user':
                            if (user?.id) showItem = false;
                            break;
                        case 'user':
                            if (!user) showItem = false;
                            break;
                        case 'admin':
                            if (!user?.isAdmin) showItem = false;
                            break;
                        case 'salesAdmin':
                            if (!(user?.isAdmin || user?.isSalesAdmin)) showItem = false;
                            break;
                        case 'all':
                            break;
                        default:
                            console.info(
                                'Unsupported allowed value for route:',
                                allowedItem,
                                'Route:',
                                item
                            );
                    }
                }
            }

            if (!showItem) continue;

            if (item.handle?.showDividerBeforeThis)
                _items.push(
                    <Divider
                        aria-hidden='true'
                        role='separator'
                        key={`divider-${item.path}`}
                        component='li'
                        sx={{
                            'pt': 2.75,
                            'mb': 1,
                            'borderColor': 'rgba(255, 255, 255, 0.75) !important',
                            'borderBottomWidth': '1.5px',
                            'textTransform': 'uppercase',
                            'color': '#fff !important',
                            'letterSpacing': '2px',
                            'fontSize': '14px !important',
                            'fontWeight': 300,
                            '&:before, &:after': {
                                borderColor: 'rgba(255, 255, 255, 0.75) !important',
                                borderTopWidth: '1.5px'
                            }
                        }}
                    >
                        {item.handle.i18nDividerKey ? t(item.handle.i18nDividerKey) : null}
                    </Divider>
                );

            const type = item.handle?.type;

            const id_path = item.path.replaceAll('/', '-');

            switch (type) {
                case 'hidden':
                    break;
                case 'collapse':
                    _items.push(
                        <li key={item.path} id={`${type}${id_path}`}>
                            <NavCollapse key={item.path} level={1} menu={item} />
                        </li>
                    );
                    break;
                case 'item':
                    _items.push(
                        <li key={item.path} id={`${type}${id_path}`}>
                            <NavItem item={item} level={1} />
                        </li>
                    );
                    break;
                default:
                    console.error('Unsupported value for route type:', type, 'Route:', item);
            }
        }

        return _items;
    }, [user, mainRouteChildren, t]);

    return (
        <List
            className='no-print'
            component='ul'
            sx={{
                py: 0,
                mt: 2,
                mb: 1.25,
                height: '100%',
                pl: '10px',
                pr: '12px',
                flexGrow: 1,
                overflow: 'auto',
                ...scrollBarStylesOnBlack
            }}
        >
            {items}
        </List>
    );
}

export default MenuList;
